import { Menu } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

export default function IconSelector({ menus }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
        <EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5" />
      </Menu.Button>

      <Menu.Items className="absolute right-0 z-10 mt-2 w-auto whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          {menus.map((menu, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <button
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    active ? "bg-gray-300 text-gray-900" : "text-gray-900"
                  } ${menu.disabled ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={menu.action}
                  disabled={menu.disabled}
                >
                  {menu.label}
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  );
}
