import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ApplicantCard from "../../../components/ApplicantCard";
import Application from "../../../api/Application";
import JobPosition from "../../../api/JobPosition";
import { DashboardLayout } from "../../../components/organisms/Layout";
import ApplicationLogs from "../../../api/ApplicationLogs";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import CardLoading from "../../../components/Loading/CardLoading";
import { DropActionButtonDropdown } from "../../../components/TableActionDropdown";
import ArchivedJob from "../../../api/ArchivedJob";
import { decodeIds, encodeIds } from "../../../utils";
import {
  applicantsIcon,
  declineIcon,
  hiredIcon,
  pendingIcon,
  positionIcon,
} from "../../../assets/images";
import "./ViewJob.scss";
import NewConfirmationModal from "../../../components/modals/NewConfirmationModal";
import { useDispatch } from "react-redux";
import { setJobPositionTitle } from "../../../store/authSlice";

const CANCELED = "canceled";
const REJECTED = "rejected";
const ACTIVE = "applied";

const ViewJob = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isApplicantsLoading, setApplicantsLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [active, setActive] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [jobPosition, setJobPosition] = useState({});
  const [jobPositions, setJobPositions] = useState([]);
  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [decodedId, setDecodedId] = useState(decodeIds(params?.id)[0]);
  const [showMoreGeneral, setShowMoreGeneral] = useState(false); //Benji wanted me to make the buttons control both of the paragraphs and not control each on a general level. The other functions become obsolete as a result. Didnt remove them because dont know if you guys wanna handle this some other way.
  const [filteredApplicants, setFilteredApplicants] = useState("all");
  const [isScrollable, setIsScrollable] = useState(false);
  const scrollContainerRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      if (isMobile) {
        setFilteredApplicants("pending");
      } else {
        setFilteredApplicants("all");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScroll = (direction) => {
    const scrollContainer = scrollContainerRef.current;
    const scrollStep = 100;
    if (direction === "left") {
      scrollContainer.scrollLeft -= scrollStep;
    } else if (direction === "right") {
      scrollContainer.scrollLeft += scrollStep;
    }
  };

  useEffect(() => {
    loadJobPositions();
    loadApplications();
    loadEmployees();
    uploadApplicationEvent();
  }, [decodedId]);

  useEffect(() => {
    setDecodedId(decodeIds(params?.id)[0]);
  }, [location]);

  const loadJobPositions = async () => {
    try {
      setLoading(true);
      const response = await JobPosition.getAll();
      const sortedJobs = response.data.data.filter(
        (j) => j?.archived_at == null && j?.deleted_at == null
      );
      setJobPositions(sortedJobs);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error(t("common.something-went-wrong-please-try-again"));
      }
      setLoading(false);
    }
  };

  const loadApplications = async () => {
    try {
      const job = await JobPosition.getById(decodedId);
      document.title = `HeyHire Portal-Viewing-${job?.data?.data?.title}`;
      setJobPosition(job.data.data);
      dispatch(setJobPositionTitle(job?.data?.data));
      const response = await Application.getByPositionId(decodedId);
      setApplications(response.data.data);
      // check for rejected
      const active = response.data.data.filter((r) => r.status === ACTIVE);
      const cancel = response.data.data.filter((r) => r.status === CANCELED);
      const rej = response.data.data.filter((r) => r.status === REJECTED);

      const sortActive = active.sort(function (a, b) {
        return a?.created_at > b?.created_at
          ? -1
          : a?.created_at < b?.created_at
          ? 1
          : 0;
      });
      setActive(sortActive);
      setRejected(rej);
      setCanceled(cancel);
      setLoading(false);
      setApplicantsLoading(false);
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load job. Please try again.");
      }
    }
  };

  const uploadApplicationEvent = () => {
    const body = {
      event_type: "view",
      entity_type: "job-position",
      entity_id: decodedId,
    };
    ApplicationLogs.addEvent(body)
      .then((response) => {})
      .catch((error) => console.log("uploadApplicationEvent -> error", error));
  };

  const loadEmployees = async () => {
    try {
      const tempUsers = await JobPosition.getHiredEmployees(decodedId);
      setCurrentEmployees(tempUsers.data.data);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/subscription");
      } else {
        toast.error("Failed to load Current Employees. Please try again.");
      }
    }
  };

  const ArchivePosition = async () => {
    setLoading(true);
    try {
      await JobPosition.archive(jobPosition.id);
      toast.success(`${jobPosition?.title} has been Archived!`);
      loadApplications();
      setLoading(false);
      setIsModalVisible(false);
    } catch (error) {
      setLoading(false);
      setIsModalVisible(false);
      toast.error("Failed to archive job. Please try again.");
    }
  };

  const UnarchivePosition = async () => {
    setLoading(true);
    try {
      await ArchivedJob.unachiveJob(jobPosition.id);
      toast.success(`${jobPosition?.title} has been Restored!`);
      loadApplications();
      setLoading(false);
      setIsModalVisible(false);
    } catch (error) {
      setIsModalVisible(false);
      setLoading(false);
      toast.error("Failed to archive job. Please try again.");
    }
  };

  const DeletePosition = async () => {
    setLoading(true);
    try {
      await JobPosition.delete(jobPosition.id);
      toast.success(`${jobPosition?.title} has been Deleted!`);
      setLoading(false);
      setIsModalVisible(false);
    } catch (error) {
      setIsModalVisible(false);
      setLoading(false);
      toast.error("Failed to archive job. Please try again.");
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", handleScrollButtonVisibility);
    handleScrollButtonVisibility();

    return () => {
      scrollContainer.removeEventListener(
        "scroll",
        handleScrollButtonVisibility
      );
    };
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const totalTagWidth = Array.from(scrollContainer.children).reduce(
      (acc, child) => acc + child.offsetWidth,
      0
    );
    setIsScrollable(totalTagWidth > scrollContainer.offsetWidth);
  }, []);

  const handleScrollButtonVisibility = () => {
    const scrollContainer = scrollContainerRef.current;
    const maxScrollPosition =
      scrollContainer.scrollWidth - scrollContainer.clientWidth;
    const isLeftScrollDisabled = scrollContainer.scrollLeft === 0;
    const isRightScrollDisabled =
      scrollContainer.scrollLeft >= maxScrollPosition;

    const leftButton = document.querySelector(".scroll-button.left");
    const rightButton = document.querySelector(".scroll-button.right");

    if (leftButton) {
      leftButton.style.display = isLeftScrollDisabled ? "none" : "block";
    }

    if (rightButton) {
      rightButton.style.display = isRightScrollDisabled ? "none" : "block";
    }
  };

  const handleDelete = () => {
    setType("delete");
    setIsModalVisible(true);
  };

  const handleArcheive = () => {
    if (jobPosition?.archived_at != null) {
      setType("Unarchive");
    } else {
      setType("archeive");
    }
    setIsModalVisible(true);
  };

  return (
    <DashboardLayout darmo={true} bgColor="bg-white">
      <div className="m-auto">
        <div className="flex overflow-x-auto mt-4 mb-0 md:mb-6">
          {isScrollable && (
            <button
              className="px-2 py-0 my-6 scroll-button left"
              onClick={() => handleScroll("left")}
            >
              <i className="far fa-less-than" />
            </button>
          )}
          <div
            ref={scrollContainerRef}
            className="flex flex-row overflow-x-auto mb-4 md:mb-2"
          >
            {jobPositions?.map((jp, index) => (
              <Link
                key={index}
                to={`/business/view-job/${encodeIds(jp?.id)}`}
                className={`py-2 w-auto cursor-pointer rounded-xl px-6 flex items-center justify-center text-white text-sm
                font-bold shadow-md ${
                  jp?.title === jobPosition?.title
                    ? "bg-midnight hover:text-white"
                    : "bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50"
                } mr-2 whitespace-nowrap`}
              >
                {jp?.title === jobPosition?.title && (
                  <img src={positionIcon} alt="logo" className="mr-2" />
                )}
                <span className="my-auto">{jp?.title}</span>
              </Link>
            ))}
          </div>
          {isScrollable && (
            <button
              className="px-2 py-0 text-black my-6 scroll-button right"
              onClick={() => handleScroll("right")}
            >
              <i className="far fa-greater-than" />
            </button>
          )}
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center -mt-2">
          <div className="my-auto mt-1 hidden md:block">
            <h4 className="font-bold text-2xl">
              <span>Open Position: </span>
              <span className="text-primary-500">
                {jobPosition?.title}{" "}
                {jobPosition?.archived_at != null ? "[ARCHIVED]" : ""}
              </span>
            </h4>
          </div>

          <div className="my-4 block md:hidden w-full text-center">
            <div className="flex items-center justify-center">
              <hr className="border-primary-500 flex-grow mr-2" />
              <h4 className="font-bold text-2xl max-w-[70%]">
                <span className="text-primary-500">
                  {jobPosition?.title}{" "}
                  {jobPosition?.archived_at != null ? "[ARCHIVED]" : ""}
                </span>
              </h4>
              <hr className="border-primary-500 flex-grow ml-2" />
            </div>
          </div>

          <div className="h-min flex items-center w-auto">
            <DropActionButtonDropdown
              menus={[
                {
                  label: "Edit Position",
                  action: () => navigate("/job-positions/edit/" + params?.id),
                },
                {
                  label: `${
                    jobPosition?.archived_at != null
                      ? "Restore the Position"
                      : "Archive Position"
                  }`,
                  action: () => handleArcheive(),
                },
                // {
                //   label: "Delete Position",
                //   action: () => handleDelete(),
                //   disabled: jobPosition.deleted_at != null,
                // },
              ]}
            />
          </div>
        </div>

        <div className="hidden md:block">
          <div className="flex mt-6 border-4 overflow-auto rounded-xl border-gray-300 bg-white">
            <div className="flex-1 p-5">
              <div className="font-bold mb-1 border-b border-gray-300 text-base">
                Position Description
              </div>
              {jobPosition?.description && (
                <p className="mt-1 leading-tight text-ellipsis">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: showMoreGeneral
                        ? jobPosition?.description
                        : `${jobPosition?.description?.substring(0, 175)}`,
                    }}
                  />
                  {jobPosition?.description?.length > 175 && (
                    <button
                      className="btn font-semibold text-blue-900 text-sm"
                      onClick={() => setShowMoreGeneral(!showMoreGeneral)}
                    >
                      {!showMoreGeneral ? "Show more" : "Show Less"}
                    </button>
                  )}
                </p>
              )}
            </div>

            <div className="flex-1 p-5 border-gray-200">
              <div className="font-bold mb-1 border-b border-gray-300 text-base">
                Requirements
              </div>
              {jobPosition?.experience && (
                <p className="mt-1 leading-tight">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: showMoreGeneral
                        ? jobPosition?.experience
                        : `${jobPosition?.experience?.substring(0, 150)}`,
                    }}
                  />
                  {jobPosition?.experience?.length > 150 && (
                    <button
                      className="btn font-semibold text-blue-900 text-sm"
                      onClick={() => setShowMoreGeneral(!showMoreGeneral)}
                    >
                      {!showMoreGeneral ? "Show more" : "Show Less"}
                    </button>
                  )}
                </p>
              )}
            </div>
            <div className="flex-1 p-5">
              <div className="font-bold mb-1 border-b border-gray-300 text-base">
                Required Certificates
              </div>
              {jobPosition?.certifications &&
              jobPosition?.certifications.length > 0 ? (
                jobPosition?.certifications.map((cert, i) => (
                  <div key={i} className="flex justify-between">
                    <p className="mt-1 leading-tight">{cert?.name}</p>
                  </div>
                ))
              ) : (
                <p>None</p>
              )}
            </div>
          </div>
        </div>

        <hr className="border-primary-500 w-full mt-6 block md:hidden" />
        <hr className="border-primary-500 w-full mt-2 block md:hidden" />

        <div className="flex overflow-x-auto md:mt-6 mt-2">
          <div className="hidden md:block">
            <div
              onClick={() => setFilteredApplicants("all")}
              className={`py-2 w-auto cursor-pointer rounded-xl px-6 flex items-center justify-center text-white text-sm
                font-bold shadow-md ${
                  filteredApplicants === "all"
                    ? "bg-midnight"
                    : "bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50"
                } mr-2 whitespace-nowrap`}
            >
              <img src={applicantsIcon} alt="logo" className="mr-2 my-auto" />
              <span className="my-auto">
                All Applicants (
                <span className="font-arial">
                  {currentEmployees?.length +
                    active?.length +
                    rejected?.length +
                    canceled?.length}
                </span>
                )
              </span>
            </div>
          </div>

          <div
            onClick={() => setFilteredApplicants("pending")}
            className={`py-2 w-auto cursor-pointer rounded-xl px-6 flex items-center justify-center text-white
                text-sm font-bold shadow-md ${
                  filteredApplicants === "pending"
                    ? "bg-midnight"
                    : "bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50"
                } mr-2 whitespace-nowrap`}
          >
            <img src={pendingIcon} alt="pending-logo" className="mr-1" />
            <span className="my-auto">
              Pending (<span className="font-arial">{active?.length}</span>)
            </span>
          </div>

          <div
            onClick={() => setFilteredApplicants("hired")}
            className={`py-2 w-auto cursor-pointer rounded-xl px-6 flex items-center justify-center text-white text-sm
                font-bold shadow-md ${
                  filteredApplicants === "hired"
                    ? "bg-midnight"
                    : "bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50"
                } mr-2 whitespace-nowrap`}
          >
            <img src={hiredIcon} alt="hired-logo" className="mr-1.5" />
            <span className="my-auto">
              Hired (
              <span className="font-arial">{currentEmployees?.length}</span>)
            </span>
          </div>

          <div
            onClick={() => setFilteredApplicants("canceled")}
            className={`py-2 w-auto cursor-pointer rounded-xl px-6 flex items-center justify-center text-white text-sm
                font-bold shadow-md ${
                  filteredApplicants === "canceled"
                    ? "bg-midnight"
                    : "bg-primary-500 hover:bg-primary-400 transition-all hover:opacity-50"
                } whitespace-nowrap`}
          >
            <img src={declineIcon} alt="decline-logo" className="mr-1.5" />
            <span className="my-auto">
              Cancelled (
              <span className="font-arial">
                {canceled?.length + rejected?.length}
              </span>
              )
            </span>
          </div>
        </div>

        {(filteredApplicants === "all" || filteredApplicants === "pending") && (
          <div className={`${filteredApplicants === "all" ? "pt-6" : "py-6"}`}>
            <p className="font-bold mb-2 text-xl text-center md:text-left mt-12 md:mt-0">
              Pending Applications
            </p>
            <div className="bg-[#F6F6F6] py-4 px-4 rounded-2xl">
              {isApplicantsLoading ? (
                <div className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <CardLoading key={index} />
                  ))}
                </div>
              ) : (
                <ul className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                  {active.map((application) => (
                    <ApplicantCard
                      key={application.id}
                      applicant={application.seeker}
                      application={application}
                      type={"applicant"}
                      positionId={decodedId}
                    />
                  ))}
                </ul>
              )}
              {!isApplicantsLoading && active.length === 0 && (
                <>
                  <div
                    className="w-full p-4 whitespace-nowrap text-start hidden md:block"
                    colSpan={12}
                  >
                    <p>No pending applicants yet</p>
                  </div>

                  <div
                    className="w-full p-4 whitespace-nowrap text-start block md:hidden"
                    colSpan={12}
                  >
                    <div className="border border-purple-600 px-4 py-10 rounded-xl mx-8">
                      <p className="text-center">No pending applicants yet</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {(filteredApplicants === "all" || filteredApplicants === "hired") && (
          <div className={`${filteredApplicants === "all" ? "pt-6" : "py-6"}`}>
            <p className="font-bold mb-2 text-xl text-center md:text-left mt-12 md:mt-0">
              Hired Applicants
            </p>
            <div className="bg-[#F6F6F6] py-4 px-4 rounded-2xl">
              {isApplicantsLoading ? (
                <div className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <CardLoading key={index} />
                  ))}
                </div>
              ) : (
                <ul className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                  {currentEmployees.map((employee) => (
                    <ApplicantCard
                      key={employee.id}
                      applicant={employee.seeker}
                      application={employee}
                      type={"employee"}
                      positionId={decodedId}
                    />
                  ))}
                </ul>
              )}
              {currentEmployees.length === 0 && (
                <>
                  <div
                    className="w-full p-4 whitespace-nowrap text-start hidden md:block"
                    colSpan={12}
                  >
                    <p>No hires yet</p>
                  </div>
                  <div
                    className="w-full whitespace-nowrap text-start block md:hidden"
                    colSpan={12}
                  >
                    <div className="border border-purple-600 px-4 py-10 rounded-xl mx-8">
                      <p className="text-center">No hires yet</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {(filteredApplicants === "all" ||
          filteredApplicants === "canceled") && (
          <div className="py-6">
            <p className="font-bold mb-2 text-xl text-center md:text-left mt-12 md:mt-0">
              Rejected/Cancelled
            </p>
            <div className="bg-[#F6F6F6] py-4 px-4 rounded-2xl">
              <ul className="grid custom-screen-size md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                {[...canceled, ...rejected].map((application) => (
                  <ApplicantCard
                    key={application.id}
                    applicant={application.seeker}
                    application={application}
                    type={"applicant"}
                    positionId={decodedId}
                  />
                ))}
              </ul>
              {canceled.length === 0 && rejected.length === 0 && (
                <>
                  <div
                    className="w-full p-4 whitespace-nowrap hidden md:block"
                    colSpan={12}
                  >
                    <p>Rejected / Cancelled Applicants will appear here</p>
                  </div>
                  <div
                    className="w-full text-start block md:hidden"
                    colSpan={12}
                  >
                    <div className="border border-purple-600 px-4 py-10 rounded-xl mx-8">
                      <p className="text-center">
                        Rejected / Cancelled Applicants will appear here
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {isModalVisible && (
        <NewConfirmationModal
          open={isModalVisible}
          setOpen={setIsModalVisible}
          type={type}
          onClick={
            type === "delete"
              ? DeletePosition
              : jobPosition?.archived_at != null
              ? UnarchivePosition
              : ArchivePosition
          }
          jobPosition={jobPosition}
        />
      )}
    </DashboardLayout>
  );
};

export default ViewJob;
