import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import JobPosition from "../api/JobPosition";
import Category from "../api/Category";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import TableActionDropdown from "../components/TableActionDropdown";
import Modal from "../components/Modal";
import AddEditPredefinedJob from "../components/modals/AddEditPredefinedJob";
import { useCustomEventListener } from "react-custom-events";
import DeleteModal from "../components/modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { DashboardLayout } from "../components/organisms/Layout";
import { encodeIds } from "../utils";
import { selectSelectedLocation } from "../store/authSlice";
import Table from "../components/atoms/Tables/Table";
import TableLoading from "../components/Loading/TableLoading";
import { addIcon } from "../assets/images";

const getJobStatus = (job) => {
  if (job?.archived_at != null) return "Archived";
  else if (job?.deleted_at != null) return "Deleted";
  else return "Active";
};

const JobPositions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [jobToEdit, setJobToEdit] = useState(null);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [categories, setCategories] = useState([]);
  const selectedLocation = useSelector(selectSelectedLocation);

  useEffect(() => {
    loadPredefinedJobs();
    loadCategories();
    document.title = "HeyHire Portal-Active Position";
  }, [selectedLocation]);

  useCustomEventListener("closeModal", () => {
    setShowModal(false);
    setJobToEdit(null);
    setJobToDelete(null);
  });

  useCustomEventListener("dataChanged", () => {
    loadPredefinedJobs();
  });

  const loadCategories = () => {
    Category.getAll().then((response) => {
      setCategories(response.data.data);
    });
  };

  const loadPredefinedJobs = () => {
    setLoading(false);
    JobPosition.getAll()
      .then((response) => setJobs(response.data.data))
      .catch((error) => {
        if (error?.response?.status !== 403) {
          toast.error(t("common.something-went-wrong"));
        }
      })
      .then(() => setLoading(true));
  };

  const getJobCategory = (id) => {
    const _category = categories.filter((item) => item.id === id);
    return _category[0]?.name;
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6">
        <div className="overflow-x-auto overflow-hidden">
          {!loading ? (
            <TableLoading Columns={5} />
          ) : (
            <Table
              mainHeader="Positions"
              headers={
                jobs?.length > 0
                  ? [
                      t("pre-defined-jobs.position"),
                      t("common.category"),
                      "Location",
                      "No. of Applicants",
                      t("common.status"),
                      "Edit",
                      "Delete",
                    ]
                  : ["No Active Positions"]
              }
              data={jobs?.map((job, index) => (
                <tr key={index}>
                  <td
                    className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 hover:underline cursor-pointer"
                    onClick={() =>
                      navigate(`/business/view-job/${encodeIds(job?.id)}`)
                    }
                  >
                    {job?.title}
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900">
                    {getJobCategory(job?.location?.category_id)}
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900">
                    {job?.location?.name}
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900">
                    {job?.applications?.length}
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base">
                    <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {getJobStatus(job)}
                    </span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                    <div
                      className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                      onClick={() =>
                        navigate(`/job-positions/edit/${encodeIds(job.id)}`)
                      }
                    >
                      Edit
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                    <div
                      className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                      onClick={() => setJobToDelete(job)}
                    >
                      Delete
                    </div>
                  </td>
                </tr>
              ))}
              buttonData={
                <button
                  onClick={() => navigate("/job-positions/add")}
                  className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                >
                  <div className="flex">
                    <img
                      src={addIcon}
                      alt="logo"
                      className="mr-2 my-auto w-4 h-4"
                    />
                    Add Position
                  </div>
                </button>
              }
            />
          )}
        </div>

        {showModal && (
          <Modal title={jobToEdit ? "Edit Job Position" : "Add Job Position"}>
            <AddEditPredefinedJob job={jobToEdit} type={"job-position"} />
          </Modal>
        )}

        {jobToDelete && (
          <DeleteModal
            title={t("common.delete") + " Job Position"}
            name="Job Position"
            deleteRequest={() => JobPosition.delete(jobToDelete.id)}
            onSuccess={() => loadPredefinedJobs()}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default JobPositions;
