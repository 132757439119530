import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { homeIcon } from "../../../assets/images";
import RoutesList from "../../../routes";
import { useSelector } from "react-redux";
import { jobApplicantTitle, jobPositionTitle } from "../../../store/authSlice";
import { encodeIds } from "../../../utils";

const pages = [
  { name: "Projects", href: "#", current: false },
  { name: "Project Nero", href: "#", current: true },
];

export default function BusinessBreadCrumbs({ darmo }) {
  const location = useLocation();
  const pathName = location.pathname;
  const params = useParams();
  const positionTitle = useSelector(jobPositionTitle);
  const applicantName = useSelector(jobApplicantTitle);
  const typeManager = location.pathname.search("manage-users") !== -1;

  if (pathName === "/dashboard") {
    return (
      <div className="flex">
        <img
          src={homeIcon}
          className="h-[18px] w-[18px] mr-2 mt-0.5"
          aria-hidden="true"
        />
        <span className="text-[15px] font-extrabold text-gray-600">Home</span>
      </div>
    );
  }

  const crumbs = RoutesList.filter(({ path }) => {
    const pathRegex = new RegExp(`^${path.replace(/:\w+/g, "\\w+")}$`);
    return pathRegex.test(pathName);
  }).map(({ path, ...rest }) => ({
    path: Object.keys(params).length
      ? Object.keys(params).reduce(
          (path, param) => path.replace(`:${param}`, params[param]),
          path
        )
      : path,
    ...rest,
  }));

  if (crumbs.length <= 0) {
    return null;
  }

  if (crumbs[0]?.name === "Positions") {
    crumbs.push({
      name: positionTitle?.title,
      path: `/business/view-job/${encodeIds(positionTitle?.id)}`,
      current: true,
    });

    if (
      pathName.startsWith("/business/applicant/") ||
      pathName.startsWith("/business/hired-applicant/")
    ) {
      crumbs.push({
        name: applicantName,
        current: true,
      });
    }
  } else if (crumbs[0]?.name === "7Shifts") {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Intergrations",
      path: "/intergrations",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Create Banner" ||
    crumbs[0]?.name === "Edit Banner"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "QR Banners",
      path: "/business/posters",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Post Position" ||
    crumbs[0]?.name === "Edit Position"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Job Positions",
      path: "/job-positions",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Edit Messages" ||
    crumbs[0]?.name === "Add Message"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Canned Messages",
      path: "/messages/canned-messages",
      current: false,
    });
  } else if (crumbs[0]?.name === "Post Shift") {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Shifts",
      path: "/shifts",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Edit Sub User" ||
    crumbs[0]?.name === "Add Sub User"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "Manage Sub Users",
      path: typeManager ? "/business/manage-users" : "/business/sub-users",
      current: false,
    });
  } else if (
    crumbs[0]?.name === "Edit QR Code" ||
    crumbs[0]?.name === "Create QR Code"
  ) {
    crumbs.splice(crumbs.length - 1, 0, {
      name: "QR Code",
      path: "/business/qr-code",
      current: false,
    });
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex overflow-x-auto whitespace-nowrap">
        <li className="flex-shrink-0">
          <div>
            <Link
              to="/dashboard"
              className={`hover:text-gray-500 flex ${
                darmo ? "text-gray-600" : "text-white"
              }`}
            >
              <img
                src={homeIcon}
                className="h-[18px] w-[18px] flex-shrink-0 mr-2 mt-0.5"
                aria-hidden="true"
              />
              <span className="text-[15px] font-extrabold text-gray-600">
                Home
              </span>
            </Link>
          </div>
        </li>
        {crumbs?.map((crumb, index) => (
          <li key={crumb.name}>
            <div className="flex items-center">
              <svg
                className={`h-5 w-5 flex-shrink-0 ${
                  darmo ? "text-gray-600" : "text-white"
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <Link
                to={crumb.path}
                // className={`text-[15px] font-extrabold ${
                //   darmo ? "text-gray-600" : "text-white"
                // } hover:text-gray-500`}
                className={`text-[15px] font-extrabold ${
                  darmo ? "" : "text-white"
                } ${
                  index === crumbs.length - 1
                    ? "text-primary-500"
                    : "text-gray-600"
                } hover:text-gray-500`}
                aria-current={crumb.current ? "page" : undefined}
              >
                {crumb.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
