import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "antd";

import DashboardStat from "../components/molecules/DashboardStat";
import DashboardTable from "../components/molecules/DashboardTable";
import "../components/molecules/DashboardTable/DashboardTable.scss";

import { DashboardLayout } from "../components/organisms/Layout";
import DashboardApi from "../api/Dashboard";
import Category from "../api/Category";
import User from "../api/User";
import useFormatPhoneNumber from "../hooks/FormatPhoneNumber";
import { useNavigate } from "react-router-dom";
import { DateFormat, encodeIds, formatPhone } from "../utils";
import { defaultUserImage } from "../assets/images";
import { darkmode } from "../store/authSlice";
import { useSelector } from "react-redux";
import Table from "../components/atoms/Tables/Table";

const Dashboard = () => {
  const { t } = useTranslation();
  const formatPhoneNumber = useFormatPhoneNumber;
  const navigate = useNavigate();

  const [recentLogins, setRecentLogins] = useState([]);
  const [recentLocations, setRecentLocations] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [recentSeekers, setRecentSeekers] = useState([]);
  const [recentApplications, setRecentApplications] = useState([]);
  const [categories, setCategories] = useState();
  const mode = useSelector(darkmode);

  const loadRecentLogins = async () => {
    const response = await DashboardApi.getRecentLogins();
    setRecentLogins(response.data.data);
  };

  const loadUsers = () => {
    User.getAll().then((response) => {
      setTotalUsers(response.data.data);
    });
  };

  const loadCategories = () => {
    Category.getAll().then((response) => setCategories(response.data.data));
  };

  const loadRecentLocations = async () => {
    const response = await DashboardApi.getRecentLocations();
    setRecentLocations(response.data.data);
  };

  const loadRecentSeekers = async () => {
    const response = await DashboardApi.getRecentSeekers();
    setRecentSeekers(response.data.data);
  };

  const loadRecentApplications = () => {
    DashboardApi.getRecentApplications().then((response) =>
      setRecentApplications(response.data.data)
    );
  };

  const getUserRole = (user) => {
    if (user?.roles && user?.roles?.length > 0) return user?.roles[0]?.name;
    else return "-";
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Dashboard";
    loadRecentLogins();
    loadRecentLocations();
    loadRecentSeekers();
    loadRecentApplications();
    loadUsers();
    loadCategories();

    navigate("/admin/dashboard");
  }, [navigate]);

  return (
    <DashboardLayout darmo={true}>
      <div className="pb-8">
        <h1 className="text-lg font-bold tracking-tight pb-5 text-[#2C3E50] mt-4 md:mt-2">
          {t("dashboard.your-dashboard")}{" "}
        </h1>

        <Row gutter={[16, 24]}>
          {/* First row of tables */}
          <Col xs={24} xl={12}>
            <div className="lg:overflow-hidden h-full">
              <Table
                title={t("dashboard.recent-logins")}
                headers={
                  recentLogins?.length > 0
                    ? [
                        t("common.full-name"),
                        t("common.last-login"),
                        t("common.location"),
                      ]
                    : ["No users yet"]
                }
                data={recentLogins?.slice(0, 5).map((recentLogin, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1 w-1/3">
                      <div className="flex items-center">
                        <div className="h-9 w-9 flex-shrink-0 transform transition duration-200 ease-in-out hover:scale-110">
                          <img
                            className="h-9 w-9 rounded-full"
                            src={
                              recentLogin?.photo?.thumb_url || defaultUserImage
                            }
                            alt="Profile"
                          />
                        </div>
                        <div className="ml-2">
                          <div className="font-medium text-gray-900">
                            {`${recentLogin?.first_name} ${recentLogin?.last_name}`}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                      <div className="text-gray-900">
                        {recentLogin?.last_login ||
                          "This user has not logged in yet"}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                      <div className="text-gray-900">
                        {recentLogin?.location?.name}
                      </div>
                    </td>
                  </tr>
                ))}
              />
            </div>
          </Col>
          <Col xs={24} xl={12}>
            <div className="lg:overflow-hidden h-full">
              <Table
                title={t("dashboard.latest-business")}
                headers={
                  recentLocations?.length > 0
                    ? [
                        t("common.company-name"),
                        t("common.category"),
                        t("common.owner"),
                      ]
                    : ["No business yet"]
                }
                data={recentLocations
                  ?.slice(0, 5)
                  .map((recentLocation, index) => {
                    const _category = categories?.find(
                      (c) => c?.id === recentLocation?.category_id
                    );
                    return (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1 w-1/3">
                          {recentLocation?.company?.name}
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                          <div className="text-gray-900">{_category?.name}</div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                          <div className="text-gray-900">
                            {recentLocation?.user.length > 0
                              ? `${recentLocation?.user[0]["first_name"]} ${recentLocation?.user[0]["last_name"]}`
                              : "-"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 24]} className="mt-4">
          {/* Second row of tables */}
          <Col xs={24} xl={12}>
            <div className="lg:overflow-hidden h-full">
              <Table
                title={t("dashboard.latest-job-seekers")}
                headers={
                  recentSeekers?.length > 0
                    ? [
                        t("common.full-name"),
                        t("common.phone"),
                        t("common.city"),
                        t("common.state"),
                      ]
                    : ["No seekers yet"]
                }
                data={recentSeekers?.slice(0, 5).map((recentSeeker, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1 w-1/3">
                      <div className="flex items-center">
                        <div className="h-9 w-9 flex-shrink-0 transform transition duration-200 ease-in-out hover:scale-110">
                          <img
                            className="h-9 w-9 rounded-full"
                            src={
                              recentSeeker?.photo?.thumb_url || defaultUserImage
                            }
                            alt="Profile"
                          />
                        </div>
                        <div className="ml-2">
                          <div className="font-medium text-gray-900">
                            {`${recentSeeker?.first_name} ${recentSeeker?.last_name}`}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                      <div className="text-gray-900">
                        {formatPhone(recentSeeker?.phone)}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                      <div className="text-gray-900">
                        {recentSeeker?.city || "-"}
                      </div>
                    </td>
                    <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                      <div className="text-gray-900">
                        {recentSeeker?.state || "-"}
                      </div>
                    </td>
                  </tr>
                ))}
              />
            </div>
          </Col>
          <Col xs={24} xl={12}>
            <div className="lg:overflow-hidden h-full">
              <Table
                title={t("dashboard.latest-application-submissions")}
                headers={
                  recentApplications?.length > 0
                    ? [
                        t("common.applicants-name"),
                        t("common.applied-for"),
                        t("common.location"),
                      ]
                    : ["No applicants yet"]
                }
                data={recentApplications
                  ?.slice(0, 5)
                  .map((recentApplication, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1 w-1/3">
                        <div className="flex items-center">
                          <div className="h-9 w-9 flex-shrink-0 transform transition duration-200 ease-in-out hover:scale-110">
                            <img
                              className="h-9 w-9 rounded-full"
                              src={
                                recentApplication?.seeker?.photo?.thumb_url ||
                                defaultUserImage
                              }
                              alt="Profile"
                            />
                          </div>
                          <div className="ml-2">
                            <div className="font-medium text-gray-900">
                              {recentApplication?.seeker?.first_name}{" "}
                              {recentApplication?.seeker?.last_name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                        <div className="text-gray-900">
                          {recentApplication?.position?.title}
                        </div>
                      </td>
                      <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base w-1/3">
                        <div className="text-gray-900">
                          {recentApplication?.location?.name}
                        </div>
                      </td>
                    </tr>
                  ))}
              />
            </div>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
