import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loading = ({
  count = 5,
  size = "4xl",
  type = "default",
  header = "Loading...",
}) => {
  return (
    <>
      {type === "round" ? (
        <i className="block text-center py-48 text-5xl text-violet-600 fad fa-circle-notch fa-spin" />
      ) : (
        <div className={`text-${size}`}>
          <p className="text-sm text-center"> {header}</p>
          <Skeleton className={"my-2"} count={count} />
        </div>
      )}
    </>
  );
};

export default Loading;
