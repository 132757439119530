import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Toggle from "../../components/form/Toggle";
import { selectRoles, selectUser, setUser } from "../../store/authSlice";

import User from "../../api/User";
import { useEffect } from "react";

const titleByRole = (role, isEnterprise) => {
  if (role === "owner" && isEnterprise) return "manager";
  if (role === "regional_manger") return "Employee";
  if (role === "owner" && !isEnterprise) return null;
  else return null;
};

const NotificationView = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const [emailNotifications, setEmailNotifications] = useState(0);
  const [textNotifications, setTextNotifications] = useState(0);
  const [chatAlerts, setChatAlerts] = useState(0);
  const role = roles[0];
  const isEnterprise = user?.company?.is_enterprise === 1;
  const pos = titleByRole(role, isEnterprise);

  useEffect(() => {
    setEmailNotifications(user?.email_notifications);
    setTextNotifications(user?.text_notifications);
    setChatAlerts(user?.chat_alerts);
  }, [user]);

  const setNotificationSettings = (body) => {
    setEmailNotifications(body?.email_notifications);
    setTextNotifications(body?.text_notifications);
    setChatAlerts(body?.chat_alerts);
    User.setPreferences(body)
      .then((response) => {
        dispatch(setUser(response.data.data));
      })
      .catch((error) => {
        console.log("error", error.response.data);
      });
  };

  return (
    <div className="flex flex-col w-full">
      <div>
        <h5 className="font-bold text-lg">Notification & Alerts</h5>
        <hr className="mt-1 h-0.5 bg-midnight opacity-10" />
      </div>
      <div className="flex w-full flex-col">
        <div className="flex justify-between py-2">
          <div className="text mt-2">
            <h6 className="leading-4 font-medium mb-1">Email Notifications</h6>
            <p className="text-sm leading-4">
              Receive an email notification for every Job Application submitted.
            </p>
          </div>
          <div className="my-auto">
            <Toggle
              value={emailNotifications}
              onChange={(e) => {
                let _value = emailNotifications ? 0 : 1;
                const body = {
                  email_notifications: _value,
                  text_notifications: textNotifications,
                  chat_alerts: chatAlerts,
                };
                setNotificationSettings(body);
              }}
            />
          </div>
        </div>
        <hr className="mt-1 h-0.5 bg-midnight opacity-10" />
      </div>
      <div className="flex w-full flex-col">
        <div className="flex justify-between py-2">
          <div className="text mt-2">
            <h6 className="leading-4 font-medium mb-1">Text Notifications</h6>
            <p className="text-sm leading-4">
              Receive a text message for every Job Application submitted.
            </p>
          </div>
          <div className="my-auto">
            <Toggle
              value={textNotifications}
              onChange={(e) => {
                let _value = textNotifications ? 0 : 1;
                const body = {
                  email_notifications: emailNotifications,
                  text_notifications: _value,
                  chat_alerts: chatAlerts,
                };
                setNotificationSettings(body);
              }}
            />
          </div>
        </div>
        <hr className="mt-1 h-0.5 bg-midnight opacity-10" />
      </div>
      <div className="flex w-full flex-col">
        <div className="flex justify-between py-2">
          <div className="text mt-2">
            <h6 className="leading-4 font-medium mb-1">Chat Alerts</h6>
            <p className="text-sm leading-4">
              Receive a text message for Text Message replies without a response
              after 5 minutes
            </p>
          </div>
          <div className="my-auto">
            <Toggle
              value={chatAlerts}
              onChange={(e) => {
                let _value = chatAlerts ? 0 : 1;
                const body = {
                  email_notifications: emailNotifications,
                  text_notifications: textNotifications,
                  chat_alerts: _value,
                };
                setNotificationSettings(body);
              }}
            />
          </div>
        </div>
        <hr className="mt-1 h-0.5 bg-midnight opacity-10" />
      </div>
      {pos && (
        <div className="flex w-full flex-col">
          <div className="flex justify-between py-2">
            <div className="text">
              <p className="text-lg"></p>
              <p className="text-sm">
                Receive an email when a {pos} hires an candidate{" "}
              </p>
            </div>
            <div className="my-auto">
              <Toggle />
            </div>
          </div>
          <hr className="mt-1 h-0.5 bg-midnight opacity-10" />
        </div>
      )}

      <p className="text-sm w-1/3 mt-3">
        Operational emails cannot be turned off.{" "}
      </p>
      <p className="text-sm">
        These are email notifications regarding any impotant operational updates
        or changes{" "}
      </p>
    </div>
  );
};

export default NotificationView;
