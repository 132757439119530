import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import JobPosition from "../../../api/JobPosition";
import { toast } from "react-toastify";
import { encodeIds } from "../../../utils";
import { applicantIcon, hireApplicantIcon } from "../../../assets/images";
import ArchivedJob from "../../../api/ArchivedJob";
import IconSelector from "../../atoms/Selector/IconSelector";

const JobOpeningCard = ({ jobPosition, loadJobs, darmo }) => {
  const navigate = useNavigate();
  const [hiredEmployees, setHiredEmployees] = useState(0);

  const activeApp =
    jobPosition.applications.filter((f) => f.status === "applied") || [];

  useEffect(() => {
    loadEmployees();
  }, []);

  const loadEmployees = async () => {
    try {
      const tempUsers = await JobPosition.getHiredEmployees(jobPosition.id);
      setHiredEmployees(tempUsers?.data?.data.length);
    } catch (error) {
      console.error(error);
    }
  };

  const ArchivePosition = async () => {
    try {
      await JobPosition.archive(jobPosition.id);
      loadJobs();
      toast.success("Job Archived Successfully");
    } catch (error) {
      toast.error("Failed to archive job. Please try again.");
    }
  };

  const UnarchivePosition = async () => {
    try {
      await ArchivedJob.unachiveJob(jobPosition.id);
      loadJobs();
      toast.success("Job Restored Successfully");
    } catch (error) {
      toast.error("Failed to unarchive job. Please try again.");
    }
  };

  return (
    <li className="col-span-1 divide-y divide-warmgray-200 rounded-lg bg-white shadow-sm border focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-warmgray-400">
      <div className="flex w-full items-center justify-between ">
        <div className="flex-1 truncate space-x-6 p-6">
          <div
            className="flex items-center space-x-3"
            onClick={() =>
              navigate(`/business/view-job/${encodeIds(jobPosition?.id)}`)
            }
          >
            <h3
              className="truncate text-base xl:text-lg font-bold text-primary-500 cursor-pointer hover:underline"
              title={jobPosition?.title}
            >
              {jobPosition.title}
            </h3>
          </div>
        </div>
        <div className="flex">
          <div className="flex -space-x-2 overflow-hidden py-6">
            <img
              alt=""
              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
            />
            <img
              alt=""
              src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
            />
            <img
              alt=""
              src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
              className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
            />
            <img
              alt=""
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
            />
          </div>
          <div className="p-2 cursor-pointer">
            <IconSelector
              menus={[
                {
                  label:
                    jobPosition?.archived_at != null
                      ? "Restore Position"
                      : "Archive Position",
                  action: () => {
                    jobPosition?.archived_at != null
                      ? UnarchivePosition()
                      : ArchivePosition();
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-warmgray-200">
          <div className="flex w-0 flex-1 px-2 py-3">
            <div className="font-bold flex text-sm my-auto mx-auto">
              <img src={applicantIcon} className="mr-1 h-4 w-4 my-auto" />{" "}
              Applicants: ({activeApp?.length})
            </div>
          </div>
          <div className="flex w-0 flex-1">
            <div className="font-bold flex text-sm my-auto mx-auto">
              <img
                src={hireApplicantIcon}
                className="mr-1 h-4 w-4 md:w-5 md:h-5"
              />{" "}
              Hired: ({hiredEmployees})
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default JobOpeningCard;
