import React, { useEffect, useState, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import Company from "../../../api/Company";
import {useSettingsContext, useSettingsContextUpdate} from "../../../pages/admin/Settings/SettingsContext";

const Selector = ({ handleUpdate, isSectionSelected }) => {
  const [countries, setCountries] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const dropDownRef = useRef();
  const {companyName,setCompanyName} = useSettingsContext();

  useEffect(() => {
    Company.getAll().then((response) => {
      setCountries(response.data.data);

      // Set the default selected company
      if (response.data.data.length > 0) {
      }
    });
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        open &&
        dropDownRef.current &&
        !dropDownRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const updateCompanyDetails = (companyName, companyId) => {
    setSelected(companyName);
    setCompanyName(companyName);
    // handleUpdate(companyName, companyId);
    setInputValue("");
  };

  return (
    <div
      ref={dropDownRef}
      className="sm:full md:w-full lg:w-1/2 font- text-black bg-white border-gray-500 border-2"
    >
      <div
        onClick={() => setOpen(!open)}
        className={`${
          isSectionSelected ? "" : "invisible"
        } bg-white w-full p-2 flex items-center justify-between rounded ${
          !selected && "text-black-700"
        }`}
      >
        {selected
          ? selected?.length > 35
            ? selected?.substring(0, 25) + "..."
            : selected
          : "Select Company"}
        <BiChevronDown size={20} className={`${open && "rotate-180"}`} />
      </div>
      <ul
        className={`absolute z-[1000] min-w-[19rem] sm:w-full md:w-5/6 lg:w-[23.5rem] list-none overflow-hidden
         border-none bg-white bg-clip-padding text-left text-base shadow-lg  
         [&[data-te-dropdown-show]]:block
         mt-2 overflow-y-auto
         ${open ? "max-h-60" : "max-h-0"}`}
      >
        <div className="flex items-center px-2 sticky top-0 bg-white">
          <AiOutlineSearch size={18} className="text-gray-500" />
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value.toLowerCase())}
            placeholder="Search Company"
            className="text-xs placeholder:text-gray-500 p-2 outline-none"
          />
        </div>
        {countries?.map((country) => (
          <li
            key={country?.id}
            className={`p-2 text-sm hover:bg-primary-300 hover:text-black
            ${
              country?.name?.toLowerCase() === selected?.toLowerCase() &&
              "bg-primary-500 text-white"
            }
            ${
              country?.name?.toLowerCase().startsWith(inputValue)
                ? "block"
                : "hidden"
            }`}
            onClick={() => {
              updateCompanyDetails(country?.name, country?.id);
              if (country?.name?.toLowerCase() !== selected.toLowerCase()) {
                setOpen(false);
              }
            }}
          >
            {country?.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Selector;
