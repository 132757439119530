import { Form, Space, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";
import { useCustomEventListener } from "react-custom-events";
import ReactImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../../config";
import { getFormattedData } from "../../../../utils";
import AuthLayout from "../../../../components/organisms/Layout/Authlayout";
import Category from "../../../../api/Category";
import Auth from "../../../../api/auth";
import FormInput from "../../../../components/atoms/FormInput";
import FormInputWithSuffix from "../../../../components/atoms/FormInputWithSuffix";
import FormSelect from "../../../../components/atoms/FormSelect";
import FormTextarea from "../../../../components/atoms/FormTextarea";
import PlacesAutocomplete from "../../../../components/form/PlacesAutocomplete";
import GoogleMaps from "../../../../components/atoms/GoogleMaps";
import Payment from "../../../../api/Payment";
import Brand from "../../../../api/Brand";
import Input from "../../../../components/form/Input";
import { RegisterGoStep2InitialValues } from "../registerSchema";
import { darkmode, login } from "../../../../store/authSlice";
import CustomNotification from "../../../../utils/CustomNotification";
import ProgressBar from "../../../../components/atoms/ProgressBar/ProgressBar";
import ReactTags from "react-tag-autocomplete";
import JobPosition from "../../../../api/JobPosition";

const googleKey = config.GOOGLE_API;

const GoStep2 = ({ onSubmit, data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [manualAddress, setManualAddress] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const [addressData, setAddressData] = useState({});
  const [completeAddress, setCompleteAddress] = useState("");
  const [isAddressDescription, setAddressDescription] = useState(false);
  const [file, setFile] = useState("/images/register_logo.svg");
  const [imageError, setImageError] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [certifications, setCertifications] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [businessContact, setBusinessContact] = useState("");
  const apiUrl = process.env.REACT_APP_API_BASE_URL.includes("stagingapi")
    ? "https://staging.heyhire.app"
    : "https://heyhire.app";
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
    message: "",
  });
  const mode = useSelector(darkmode);
  const selectedPlan = JSON.parse(localStorage.getItem("selectedPlan")) || null;
  const { placesService, placePredictions } = usePlacesService({
    apiKey: googleKey,
    libraries: ["places", "geometry", "name"],
  });

  // fetch Business categories
  const GetBusinessCategories = async () => {
    let catData = [];
    const categories = await Category.getAll();
    const c = categories.data.data;
    for (let i = 0; i < c.length; i++) {
      const el = c[i];
      catData.push({
        label: el.name,
        value: el.id,
      });
    }
    setCategories(catData);
  };

  //place prediction
  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length) {
      placesService?.getDetails(
        { placeId: placePredictions[0].place_id },
        (placeDetails) => {
          const cityD = getFormattedData(
            placeDetails["address_components"],
            "locality"
          );
          const State = getFormattedData(
            placeDetails["address_components"],
            "administrative_area_level_1"
          );
          const ZipCode = getFormattedData(
            placeDetails["address_components"],
            "postal_code"
          );
          const address = {
            address: placeDetails.formatted_address,
            city: cityD?.long_name,
            state: State?.long_name,
            zip: ZipCode?.long_name,
            lat: placeDetails.geometry.location.lat(),
            lng: placeDetails.geometry.location.lng(),
          };
          setAddressData(address);
        }
      );
    }
  }, [placePredictions]);

  //intial load
  useEffect(() => {
    if (selectedPlan?.planName !== "go") {
      return navigate("/plans");
    } else if (
      !data?.businessName &&
      !data?.firstName &&
      !data?.lastName &&
      !data?.email &&
      !data?.phone &&
      !data?.password &&
      !data?.cpassword &&
      !data?.hearAboutUs
    ) {
      return navigate("/register?step=1");
    }
    GetBusinessCategories();
  }, []);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    touched,
    errors,
    setErrors,
    setValues,
  } = useFormik({
    initialValues: RegisterGoStep2InitialValues,
    onSubmit: async (formData) => {
      setSubmitting(true);

      const APIData = {
        address: addressData,
        location: {
          name: manualAddress ? values.locationName : addressData.name,
          url_slug: values.customUrl,
          category_id: values.businessCategory,
          description: values.businessDescription,
          business_phone: businessContact,
        },
        company: {
          name: data.businessName,
          website_url: values.websiteUrl,
          how_did_you_hear:
            data?.hearAboutUs === "Others" ? data?.others : data?.hearAboutUs,
        },
        job: {
          title: values.jobTitle,
          availability: values.jobAvailability,
          description: values.jobDescription,
          experience: values.jobExperience,
          hiring_status: "1",
          certifications: selectedCertifications
            .map((certification) => certification.id)
            .toString(),
          wage: values.jobWage,
        },
        user: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          phone: data.phone,
          password: data.password,
        },
      };

      return RegisterUser(APIData);
    },
  });

  const RegisterUser = async (dataAPi) => {
    try {
      // Reset errors and initialize newErrors object
      setErrors(null);
      const newErrors = {
        customUrl: values.customUrl.trim() === "",
        businessAddress: values.businessAddress.trim() === "",
        businessCategory: values.businessCategory === "",
        businessDescription: values.businessDescription.trim() === "",
        jobTitle: values.jobTitle.trim() === "",
        jobAvailability: values.jobAvailability === "",
        jobDescription: values.jobDescription.trim() === "",
        jobExperience: values.jobExperience.trim() === "",
        ...(manualAddress && {
          locationName: values.locationName.trim() === "",
        }),
      };

      // Check if file is default and set image error if true
      if (file === "/images/register_logo.svg") {
        setImageError(true);
        return;
      }

      // Set validation errors
      setErrors(newErrors);
      if (Object.values(newErrors).some((error) => error)) {
        return;
      }

      // Register user and handle response
      const reqData = await Auth.Register(dataAPi);
      localStorage.setItem("token", reqData.data.token);

      setShowNotification({
        show: true,
        type: "success",
        message: "Registration Successful!",
      });

      try {
        // Upload brand image
        await Brand.uploadImage(reqData?.data?.brand?.id, file);
      } catch (error) {
        console.error("Error uploading brand image:", error);
        setShowNotification({
          show: true,
          type: "error",
          message: "Error uploading brand image. Please try again.",
        });
      }

      try {
        // Add job position
        const requestBody = {
          title: values.jobTitle,
          availability: values.jobAvailability,
          description: values.jobDescription,
          experience: values.jobExperience,
          hiring_status: "1",
          wage: values.wage,
          certifications: selectedCertifications
            .map((certification) => certification.id)
            .toString(),
        };
        await JobPosition.addGoPosition(requestBody);
      } catch (error) {
        console.error("Error adding job position:", error);
        setShowNotification({
          show: true,
          type: "error",
          message: "Error adding job position. Please try again.",
        });
      }

      // Clear local storage
      localStorage.removeItem("registerStep0");
      localStorage.removeItem("registerStep1");
      localStorage.removeItem("registerStep2");
      localStorage.removeItem("selectedPlan");

      try {
        // Add payment account and handle redirection
        const paymentResponse = await Payment.addAccount(reqData.data);
        const request = {
          plan_id: 1,
          customer_stripe_id: paymentResponse?.data?.id,
        };
        const result = await Payment.getRedirectURL(request);
        const paymentUrl = result?.data?.url?.url;
        if (paymentUrl) {
          window.location.href = paymentUrl;
        } else {
          console.error("Payment URL is not available");
        }
      } catch (error) {
        console.error("Error handling payment redirection:", error);
        setShowNotification({
          show: true,
          type: "error",
          message: "Error handling payment redirection. Please try again.",
        });
      }
    } catch (error) {
      console.error("Error registering user:", error);
      setShowNotification({
        show: true,
        type: "error",
        message: "Error creating new business! Please contact service.",
      });
    }
  };

  const handleInput = (e) => {
    if (e.target.value === "") {
      setAddressDescription(false);
    }
  };

  useCustomEventListener("placeSelected", (data) => {
    const Name = data.terms[0]?.value;
    const cityD = data.terms[data.terms.length - 3]?.value;
    const State = data.terms[data.terms.length - 2]?.value;
    const ZipCode = data.zipCode ? data.zipCode : "00000";
    const addressLineCustom = data.addressLine.replaceAll(",", "");
    const address = {
      name: Name,
      address: data.description,
      city: cityD,
      state: State,
      zip: ZipCode,
      lat: data.lat,
      lng: data.lng,
    };
    setBusinessContact(data.business_number);
    setAddressData(address);
    setCompleteAddress(data.description);
    setAddressDescription(true);
    setFieldValue("businessAddress", data.addressLine);
    const _customUrl = addressLineCustom.toLowerCase().replaceAll(" ", "-");
    form.setFieldsValue({
      customUrl: _customUrl,
    });
    setFieldValue("customUrl", _customUrl);
  });

  const setMapSelectAddress = (address) => {
    setAddressData(address);
    setFieldValue("businessAddress", address?.address);
  };

  const addCertification = (certification) => {
    setSelectedCertifications([...selectedCertifications, certification]);
  };

  const removeCertification = (index) => {
    const copy = selectedCertifications.slice(0);
    copy.splice(index, 1);
    setSelectedCertifications(copy);
  };

  return (
    <>
      <AuthLayout>
        <ProgressBar currentStep={3} />
        <div className="flex flex-1 flex-col justify-center sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full rounded mx-4 h-full">
            <div className="w-full h-full">
              <div className="sm:mx-auto sm:w-full flex flex-col justify-center h-full">
                <div className="mb-8 sm:mx-auto sm:w-full lg:max-w-[920px] sm:max-w-[600px] h-full">
                  <div className="flex flex-col h-full items-center sm:rounded-lg sm:px-12 lg:px-6">
                    <p
                      className={`text-xl font-bold ${
                        mode ? "text-black" : "text-white"
                      }`}
                    >
                      Post a Job
                    </p>
                    <p
                      className={`text-sm ${
                        mode ? "text-black" : "text-white"
                      }`}
                    >
                      Complete the form below to post your job listing.
                    </p>

                    <Form
                      onFinish={handleSubmit}
                      layout="vertical"
                      className="mt-8 w-full"
                      form={form}
                    >
                      <p
                        className={`font-bold text-[18px] mb-4 ${
                          mode ? "text-black" : "text-white"
                        }`}
                      >
                        Please provide us with your business details
                      </p>
                      <Space
                        size={20}
                        direction="vertical"
                        className="relative w-full"
                      >
                        <Row gutter={[24, 18]}>
                          <Col xs={24} md={12}>
                            <>
                              <PlacesAutocomplete
                                type="go"
                                label={`Business ${
                                  manualAddress ? t("common.address") : "Name"
                                }`}
                                placeholder={
                                  manualAddress
                                    ? t("common.address-example")
                                    : "Enter Business Name  "
                                }
                                pValue={values.businessAddress}
                                isAddressSearch={manualAddress}
                                setAddressCheck={handleInput}
                                error={
                                  errors?.businessAddress
                                    ? manualAddress
                                      ? "Business Address is required"
                                      : "Business Name is required"
                                    : null
                                }
                                darmo={mode}
                              />
                              {manualAddress ? (
                                <div className="mt-4">
                                  <>
                                    <FormInput
                                      type="go"
                                      id="locationName"
                                      name="locationName"
                                      label="Location Name"
                                      placeholder="XYZ Holdings LLC"
                                      value={values.locationName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      darmo={mode}
                                    />
                                    {touched?.locationName &&
                                      errors?.locationName && (
                                        <p className="text-red-400 text-xs font-bold mt-2">
                                          The location name is required.
                                        </p>
                                      )}
                                  </>
                                </div>
                              ) : null}
                              {!manualAddress ? (
                                addressData?.address && isAddressDescription ? (
                                  <div
                                    className={`text-[12px] mt-2 px-3 ${
                                      mode ? "text-black" : "text-white"
                                    }`}
                                  >
                                    <i className="fas fa-map-marker-alt" />{" "}
                                    {completeAddress}
                                  </div>
                                ) : (
                                  <a
                                    href="#"
                                    onClick={() => setManualAddress(true)}
                                    className={`${
                                      mode
                                        ? "text-indigo-600 hover:text-indigo-500"
                                        : "text-secondary hover:text-white"
                                    } font-semibold`}
                                  >
                                    Not on Google? Enter Business Address
                                    manually
                                  </a>
                                )
                              ) : (
                                <div className="mt-4">
                                  <GoogleMaps
                                    location={{
                                      address: addressData?.address,
                                      lat: addressData?.lat,
                                      lng: addressData?.lng,
                                    }}
                                    onClickAction={setMapSelectAddress}
                                  />
                                </div>
                              )}
                            </>
                          </Col>

                          <Col xs={24} md={12}>
                            <FormSelect
                              type="go"
                              name="businessCategory"
                              label="Select Business Category"
                              description="Select the category that most closely matches your business type. When you post a new position, we'll notify users interested in working with your type of business."
                              placeholder="List business categories"
                              defaultValue={
                                !!values.businessCategory && [
                                  {
                                    label:
                                      categories[values.businessCategory - 1]
                                        ?.label,
                                    value:
                                      categories[values.businessCategory - 1]
                                        ?.value,
                                  },
                                ]
                              }
                              value={
                                !!values.businessCategory && [
                                  {
                                    label:
                                      categories[values.businessCategory - 1]
                                        ?.label,
                                    value:
                                      categories[values.businessCategory - 1]
                                        ?.value,
                                  },
                                ]
                              }
                              onBlur={handleBlur}
                              onChange={(value) =>
                                setFieldValue("businessCategory", value.value)
                              }
                              options={categories}
                              darmo={mode}
                            />
                            {touched?.businessCategory &&
                              errors?.businessCategory && (
                                <p className="text-red-400 text-xs font-bold mt-2">
                                  Business Category is required.
                                </p>
                              )}
                          </Col>
                        </Row>

                        <Row gutter={[24, 18]}>
                          <Col xs={24} md={12}>
                            <FormInput
                              name="websiteUrl"
                              label="Business Website URL"
                              placeholder="http://mywebsite.com"
                              value={values.websiteUrl}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              darmo={mode}
                            />
                            {touched?.websiteUrl && errors?.websiteUrl && (
                              <p className="text-red-400 text-xs mt-2 font-bold">
                                {errors?.websiteUrl}
                              </p>
                            )}
                          </Col>

                          <Col xs={24} md={12}>
                            <FormInputWithSuffix
                              type="go"
                              id="customUrl"
                              name="customUrl"
                              label="Custom URL"
                              addonBefore={apiUrl}
                              value={values.customUrl}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              darmo={mode}
                              setFieldValue={setFieldValue}
                            />
                            {touched?.customUrl && errors?.customUrl && (
                              <p className="text-red-400 text-xs mt-2 font-bold">
                                Custom URL is required.
                              </p>
                            )}
                          </Col>
                        </Row>

                        <Row gutter={[24, 18]}>
                          <Col xs={24} md={12}>
                            <ReactImageUploading
                              value={images}
                              onChange={(imageList, index) => {
                                const selectedFile = imageList[0]?.file;
                                if (
                                  selectedFile &&
                                  selectedFile.type === "image/svg+xml"
                                ) {
                                  setImageError(true);
                                  // Optionally, set an error message or handle the error
                                  console.error("SVG files are not allowed.");
                                } else {
                                  setImageError(false);
                                  setFile(selectedFile);
                                  setImages(imageList);
                                }
                              }}
                            >
                              {({ imageList, onImageUpload }) => (
                                <div className="flex items-center mb-7">
                                  <div className="h-28 w-28 bg-warmgray-200 selfcenter flex justify-center">
                                    {imageList && imageList.length > 0 ? (
                                      <img
                                        className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                                        src={imageList[0].dataURL}
                                      />
                                    ) : (
                                      <i
                                        className="text-white text-3xl self-center fas fa-building cursor-pointer"
                                        onClick={onImageUpload}
                                      />
                                    )}
                                  </div>
                                  <div className="ml-5">
                                    <button
                                      onClick={onImageUpload}
                                      type="button"
                                      className={`border rounded-[4px] ${
                                        mode
                                          ? "text-indigo-600 border-indigo-600"
                                          : "text-white border-white"
                                      } h-10 px-4 mb-[11px]`}
                                    >
                                      Upload Logo
                                    </button>
                                    <div
                                      className={`font-bold text-[12px] ${
                                        imageError
                                          ? "text-red-400"
                                          : mode
                                          ? "text-gray-900"
                                          : "text-white"
                                      }`}
                                    >
                                      Upload your business profile logo
                                    </div>
                                  </div>
                                </div>
                              )}
                            </ReactImageUploading>
                          </Col>

                          <Col xs={24} md={12}>
                            <FormTextarea
                              type="go"
                              name="businessDescription"
                              label="Describe Your Business"
                              placeholder="You can talk about your culture, service, mission or anything that best describes your business"
                              value={values.businessDescription}
                              onBlur={handleBlur}
                              callBack={(value) =>
                                setFieldValue("businessDescription", value)
                              }
                              darmo={mode}
                            />
                            {touched?.businessDescription &&
                              errors?.businessDescription && (
                                <p className="text-red-400 text-xs font-bold mt-2">
                                  Business Description is required.
                                </p>
                              )}
                          </Col>
                        </Row>
                        <p
                          className={`font-bold text-[18px] ${
                            mode ? "text-black" : "text-white"
                          }`}
                        >
                          Position Details
                        </p>

                        <Row gutter={[24, 18]}>
                          <Col xs={24} md={12}>
                            <FormInput
                              type="go"
                              id="jobTitle"
                              label={t("pre-defined-jobs.job-title")}
                              placeholder={t("pre-defined-jobs.job-title")}
                              value={values.jobTitle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              darmo={mode}
                            />
                            {touched?.jobTitle && errors?.jobTitle && (
                              <p className="text-red-400 text-xs font-bold mt-2">
                                Job title is required.
                              </p>
                            )}
                          </Col>
                          <Col xs={24} md={12}>
                            <FormSelect
                              type="go"
                              label="Availability"
                              placeholder="Select Availability"
                              options={[
                                { value: "full-time", label: "Full Time" },
                                { value: "part-time", label: "Part Time" },
                              ]}
                              onChange={(value) =>
                                setFieldValue("jobAvailability", value.value)
                              }
                              darmo={mode}
                              onBlur={handleBlur}
                              value={
                                !!values.jobAvailability && [
                                  {
                                    label: values.jobAvailability,
                                    value: values.jobAvailability,
                                  },
                                ]
                              }
                              defaultValue={
                                !!values.jobAvailability && [
                                  {
                                    label: values.jobAvailability,
                                    value: values.jobAvailability,
                                  },
                                ]
                              }
                            />
                            {touched?.jobAvailability &&
                              errors?.jobAvailability && (
                                <p className="text-red-400 text-xs mt-2 font-bold">
                                  Job Availability is required.
                                </p>
                              )}
                          </Col>
                        </Row>

                        <Row gutter={[24, 18]}>
                          <Col xs={24} md={12}>
                            <FormTextarea
                              id="description"
                              type="go"
                              name="description"
                              label="Description"
                              placeholder="Describe job position here."
                              value={values.jobDescription}
                              onBlur={handleBlur}
                              callBack={(value) =>
                                setFieldValue("jobDescription", value)
                              }
                              darmo={mode}
                            />
                            {touched?.jobDescription &&
                              errors?.jobDescription && (
                                <p className="text-red-400 text-xs font-bold mt-2">
                                  Job Description is required.
                                </p>
                              )}
                          </Col>

                          <Col xs={24} md={12}>
                            <FormTextarea
                              type="go"
                              name="experience"
                              label="Required Experience"
                              placeholder="Add Required Experience for the job here."
                              value={values.jobExperience}
                              onBlur={handleBlur}
                              callBack={(value) =>
                                setFieldValue("jobExperience", value)
                              }
                              darmo={mode}
                            />
                            {touched?.jobExperience &&
                              errors?.jobExperience && (
                                <p className="text-red-400 text-xs font-bold mt-2">
                                  Job Experience is required.
                                </p>
                              )}
                          </Col>
                        </Row>

                        <Row gutter={[24, 18]}>
                          <Col xs={24} md={12}>
                            <div className="block">
                              <span
                                className={`block title font-bold text-warmgray-900 ${
                                  mode ? "text-black" : "text-white"
                                }`}
                              >
                                Required Certificate
                              </span>
                              <ReactTags
                                tags={selectedCertifications}
                                suggestions={certifications}
                                onDelete={removeCertification}
                                onAddition={addCertification}
                                newTagText={"Add New Certification:"}
                                placeholderText={
                                  "Search Certifications or add your own"
                                }
                                allowNew={true}
                                allowBackspace={false}
                                classNames={{
                                  root: `p-3 border-2 rounded-md border-gray-300 focus-within:border-primary-300 shadow-sm ${
                                    mode ? "bg-white" : "bg-slate-800"
                                  }`,
                                  searchWrapper: "",
                                  searchInput: `focus:outline-0 ${
                                    mode
                                      ? "bg-white text-black"
                                      : "bg-slate-800 text-white"
                                  } custom-search-input`,
                                  rootFocused: "focus:bg-blue-500",
                                  suggestions:
                                    "p-3 min-w-[250px] z-30 bg-white flex shadow-lg cursor-pointer",
                                  suggestionActive: "bg-red-500",
                                }}
                                tagComponent={({
                                  tag,
                                  removeButtonText,
                                  onDelete,
                                }) => {
                                  return (
                                    <button
                                      className={
                                        "bg-primary-400 hover:bg-primary-500 text-white text-sm rounded-full py-1 px-2 mr-1 mb-2"
                                      }
                                      onClick={onDelete}
                                    >
                                      {tag.name}{" "}
                                      <i
                                        className={"fas fa-times-circle ml-2"}
                                      />
                                    </button>
                                  );
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={24} md={12}>
                            <Input
                              name="jobWage"
                              label={t("pre-defined-jobs.wage")}
                              type="go"
                              value={values.jobWage}
                              slug={"$"}
                              placeholder="8.50/HR + Tips & Benefts"
                              onChange={handleChange}
                              darmo={mode}
                            />
                          </Col>
                        </Row>
                      </Space>

                      <div
                        className={`title mt-12 text-center text-sm ${
                          mode ? "text-black" : "text-primary-100"
                        }`}
                      >
                        By registering, you are agreeing to the
                        <Link
                          to="https://www.heyhire.com/terms-conditions"
                          target="_blank"
                          className={`${
                            mode
                              ? "text-indigo-600 hover:text-indigo-500 "
                              : "text-secondary hover:text-white"
                          } font-semibold ml-1`}
                        >
                          Terms and Conditions
                        </Link>{" "}
                        and
                        <Link
                          to="https://www.heyhire.com/privacy"
                          target="_blank"
                          className={`ml-1 ${
                            mode
                              ? "text-indigo-600 hover:text-indigo-500 "
                              : "text-secondary hover:text-white"
                          } font-semibold`}
                        >
                          Privacy Policy
                        </Link>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="flex border-t-2 py-4 border-primary-500 justify-center">
                  <div className="flex w-full lg:w-1/2 xl:w-1/3 justify-between">
                    <button
                      className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-full bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                      type="button"
                      onClick={() => {
                        const formData = { ...values, images, addressData };
                        localStorage.setItem(
                          "registerStep2",
                          JSON.stringify(formData)
                        );
                        navigate("/register?step=2");
                      }}
                      disabled={loading}
                    >
                      Previous
                    </button>
                    <button
                      className="font-bold text-sm mr-2 px-6 py-2 w-32 rounded-full bg-primary-500 text-white hover:bg-primary-400 transition-all hover:opacity-50 disabled:opacity-50"
                      type="submit"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      Finish
                      {isSubmitting ? (
                        <i className="fad fa-circle-notch fa-spin ml-2 my-auto" />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
      {showNotification?.show && (
        <CustomNotification
          show={showNotification?.show}
          setShow={() =>
            setShowNotification({
              show: false,
              type: showNotification?.type,
              message: showNotification?.message,
            })
          }
          title={showNotification?.message}
          type={showNotification?.type}
        />
      )}
    </>
  );
};

export default GoStep2;
