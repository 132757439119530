/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";
import "./Navbar.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { emitCustomEvent } from "react-custom-events";
import {
  chatList,
  logout,
  readNotify,
  selectRoles,
  selectSelectedLocation,
  selectUser,
  setChatListOpen,
  setReadNotification,
  notificationCount,
  setNotificationCount,
  setUnreadData,
  sidebar,
} from "../../../store/authSlice";
import DropdownMenuItem from "../../DropdownMenuItem";
import { FaBars } from "react-icons/fa";
import {
  chatListIcon,
  defaultUserImage,
  heyhireBlackIcon,
  heyhireIcon,
  notifyBellIcon,
} from "../../../assets/images";
import { StackedList } from "../../atoms/StackedList";
import Notification from "../../../api/Notification";
import { useEffect } from "react";
import JobSeeker from "../../../api/JobSeeker";
import { useState } from "react";
import RBAC from "../../../api/RBAC";
import BusinessBreadCrumbs from "../BreadCrumb/BusinessBreadCrumbs";

const notAllowedroutes = ["dashboard"];

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const isSuper = !pathname.includes("/business");
  const roles = useSelector(selectRoles);
  const user = useSelector(selectUser);
  const selectedLocation = useSelector(selectSelectedLocation);
  const allowed = !notAllowedroutes.includes(pathname.split("/")[1]) || false;
  const langs = {
    en: { nativeName: "English" },
    es: { nativeName: "Spanish" },
  };
  const [notificationStackData, setNotificationStackData] = useState([]);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const openChatList = useSelector(chatList);
  const readNotification = useSelector(readNotify);
  const notifyCount = useSelector(notificationCount);
  const [isChatView, setChatView] = useState(false);
  const open = useSelector(sidebar);

  const HandleChatList = () => {
    dispatch(setChatListOpen(!openChatList));
    setMenuOpen(false);
    // navigate("/subscription");
  };

  const HandleMenuChatList = (chat) => {
    dispatch(setChatListOpen(false));
    setMenuOpen(!chat);
  };

  const HandleNotifyList = (notificationOpen) => {
    dispatch(setChatListOpen(false));
    setMenuOpen(false);
    setNotificationOpen(!notificationOpen);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  // const checkChatView = async () => {
  //   try {
  //     const payload = {
  //       location_id: selectedLocation?.id,
  //       feature: "chat",
  //       operations: "read",
  //     }
  //     const response = await RBAC.checkPermissionRBAC(payload);
  //     if(response?.data?.result){ setChatView(true);}
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const getTimeDifference = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);
    const timeDifference = Math.abs(date2 - date1);
    const secondsAgo = Math.floor(timeDifference / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    return { secondsAgo, minutesAgo, hoursAgo, daysAgo };
  };

  const { i18n } = useTranslation();
  const menu = (
    <div
      className="origin-top-right w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="py-1" role="none">
        <DropdownMenuItem
          onClick={() => navigate("/business/my-account")}
          label="Profile"
          icon="far fa-user"
        />
        {roles[0] === "super-user" && (
          <DropdownMenuItem
            label="Switch Accounts"
            icon="far fa-repeat"
            onClick={() =>
              navigate(isSuper ? "/business/dashboard" : "/dashboard")
            }
          />
        )}
        <DropdownMenuItem
          onClick={() => {
            navigate("/login");
            dispatch(logout());
          }}
          label="Logout"
          icon="far fa-sign-out"
        />
      </div>
    </div>
  );

  // useEffect(() => {
  //   if (readNotification) {
  //     getNotifications();
  //     dispatch(setReadNotification(false));
  //   }
  // }, [readNotification]);

  // useEffect(() => {
  //   if(selectedLocation && selectedLocation?.name !== "Select A Location"){checkChatView();}
  // },[selectedLocation])

  const getNotifications = async () => {
    await Notification.getAllNotifications()
      .then((response) => {
        let notifications = response?.data?.data?.payload;
        dispatch(setNotificationCount(response?.data?.data?.unread?.length));
        dispatch(setUnreadData(response?.data?.data?.unread));
        let count = 0;

        notifications?.map((item) => {
          const data = JSON.parse(item.data);
          const jobSeekerId = data.job_seeker_id;
          const jobPositionId = data.job_position_id;
          const conversation_sid = data.conversation_sid
            ? data.conversation_sid
            : null;
          const { secondsAgo, minutesAgo, hoursAgo, daysAgo } =
            getTimeDifference(item.created_at, new Date().toUTCString());

          const details = {
            id: item.id,
            name: "",
            conversation_sid: conversation_sid,
            status: item.read_at,
            email: "",
            body: data.body,
            message: data.message,
            seconds: secondsAgo,
            minutes: minutesAgo,
            hours: hoursAgo,
            days: daysAgo,
            href: "#",
          };

          // getJobSeeker(jobSeekerId, details);
          setNotificationStackData((notificationStackData) => [
            ...notificationStackData,
            details,
          ]);
        });
      })
      .catch(() => {
        console.error("Error");
      });
  };

  const getJobSeeker = async (id, details) => {
    const seeker = await JobSeeker.getById(id);

    if (seeker.data.data) {
      details.name = seeker.data.data.first_name;
      details.email = seeker.data.data.email;

      if (seeker.data.data.avatar_image === 1) {
        details.imageUrl = seeker.data.data.photo.thumb_url;
      } else {
        details.imageUrl = defaultUserImage;
      }
    }
  };

  const notifications = (
    <div
      className="w-full rounded-md shadow-xl bg-white ring-1
         ring-black ring-opacity-5 focus:outline-none border border-warmgray-200"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      <div className="py-1" role="none">
        {notificationStackData.length !== 0 && (
          <div
            onClick={() => navigate("/notifications")}
            className="relative left-3/4 ml-5 text-sm font-bold hover:text-primary-500 cursor-pointer"
          >
            View All
          </div>
        )}
        <StackedList
          data={notificationStackData}
          showButton={true}
          redirectUrl="\notifications"
        />
      </div>
    </div>
  );

  const menuLang = (
    <div
      className="origin-top-right w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex="-1"
    >
      {Object.keys(langs).map((lang) => (
        <li key={lang}>
          <button
            className={
              "block w-full px-4 py-2 " +
              (i18n.resolvedLanguage === lang &&
                " bg-primary-400 text-white hover:bg-primary-500 hover:text-white")
            }
            onClick={() => {
              i18n.changeLanguage(lang);
            }}
          >
            {langs[lang].nativeName}
          </button>
        </li>
      ))}
    </div>
  );

  const navigateBack = () => {
    return navigate(-1);
  };

  return (
    <>
      <div
        id="navbar"
        className="navbar sticky top-0 w-full bg-white flex justify-between align-middle px-4 md:px-[25px] z-[1] border-b border-warmgray-400 lg:hidden"
      >
        <div className={"flex lg:hidden flex-col justify-center"}>
          <button
            onClick={() => {
              emitCustomEvent("toggle-sidebar");
            }}
          >
            <FaBars className={"block lg:hidden"} />
          </button>
        </div>

        <div className="flex w-full">
          <div
            className="flex flex-col lg:hidden justify-center items-start cursor-pointer ml-4"
            onClick={() => navigate("/dashboard")}
          >
            <img width={100} src={heyhireBlackIcon} alt="heyhire" />
          </div>
          <div className="flex justify-end ml-auto">
            {/* <div className="flex flex-col justify-center cursor-pointer ml-5 mr-0">
              <Dropdown
                overlay={notifications}
                trigger={["click"]}
                placement="bottomRight"
                overlayClassName="max-h-[600px] overflow-hidden !top-[70px] md:!top-[65px]"
                getPopupContainer={() => document.getElementById("navbar")}
                onVisibleChange={() => HandleNotifyList(notificationOpen)}
                visible={notificationOpen}
              >
                <div>
                  <button
                    type="button"
                    className="relative inline-flex items-center py-2 px-1 text-sm font-medium text-center text-white rounded-lg"
                  >
                    <img src={notifyBellIcon} alt="notify-logo" width={36} />
                    {notifyCount !== 0 && (
                      <div className="absolute flex items-center justify-center w-4 h-4 text-[9px] font-bold text-white bg-red-600 rounded-full right-2 top-1">
                        {notifyCount > 9 ? "9+" : notifyCount}
                      </div>
                    )}
                  </button>
                </div>
              </Dropdown>
            </div> */}

            {/* {(selectedLocation?.name === "Select A Location" || isChatView) && */}
            <div className="flex flex-col justify-center cursor-pointer">
              <button
                type="button"
                className="relative inline-flex items-center py-2 px-1 text-sm font-medium text-center text-white cursor-pointer"
                onClick={HandleChatList}
              >
                <img src={chatListIcon} alt="chat-logo" width={36} />
              </button>
            </div>
            {/* } */}

            {/* <div className="flex flex-col justify-center cursor-pointer mr-0">
          <Dropdown
            overlay={menuLang}
            trigger={["click"]}
            placement="bottomRight"
            overlayClassName="!top-[70px] md:!top-[65px]"
            getPopupContainer={() => document.getElementById("navbar")}
          >
            <a
              className="ant-dropdown-link hover:text-primary-500"
              onClick={(e) => e.preventDefault()}
            >
              <i className="far fa-language" />
            </a>
          </Dropdown>
        </div> */}
          </div>
        </div>
      </div>

      <div className="w-full">
        {pathname === "/subscription" && (
          <div className="bg-red-600 text-center py-4 text-white text-[15px] font-bold flex">
            <div className="mx-auto">
              {/* {pathname === "/subscription"
                ? "Please select a subscription plan"
                : "Please update your payment method"} */}
              {pathname === "/subscription" &&
                "Please select a subscription plan"}
            </div>
            <div className="text-center">
              <div
                onClick={() => {
                  navigate("/login");
                  dispatch(logout());
                }}
                className="text-white px-4 rounded-full cursor-pointer"
              >
                <i className="far fa-sign-out mr-2" />
              </div>
            </div>
          </div>
        )}
      </div>

      {pathname !== "/subscription" && (
        <div
          id="navbarWeb"
          className="w-full bg-white hidden align-middle lg:block"
        >
          <div className="navbar sticky top-0 bg-midnight py-4 text-white text-[15px] font-bold rounded-br-3xl">
            <div
              className={`${
                open ? `lg:w-11/12 2xl:w-[90%]` : "lg:w-11/12 2xl:w-4/5"
              } px-4 md:px-4 lg:px-8 2xl:px-12`}
            >
              <img src={heyhireIcon} alt="logo" className="h-7" />
            </div>
          </div>
          <div
            className={`sticky top-0 flex w-full justify-between px-4 md:px-4 lg:px-8 2xl:px-12 ${
              open ? `2xl:w-4/5` : "2xl:w-4/5"
            } my-4`}
          >
            <div className="my-auto">
              <BusinessBreadCrumbs darmo={true} />
            </div>
            <div className="flex border border-primary-500 rounded-xl px-4 2xl:hidden">
              {/* <div className="flex flex-col justify-center cursor-pointer mr-0">
                <Dropdown
                  overlay={notifications}
                  trigger={["click"]}
                  placement="bottomRight"
                  overlayClassName="max-h-[600px] overflow-hidden rounded-xl border border-warmgray-200 shadow-lg"
                  getPopupContainer={() => document.getElementById("navbarWeb")}
                  onVisibleChange={() => HandleNotifyList(notificationOpen)}
                  visible={notificationOpen}
                >
                  <div>
                    <button
                      type="button"
                      className="relative inline-flex items-center py-2 px-1 text-sm font-medium text-center text-white rounded-lg"
                    >
                      <img src={notifyBellIcon} alt="notify-logo" width={36} />
                      {notifyCount !== 0 && (
                        <div className="absolute flex items-center justify-center w-4 h-4 text-[9px] font-bold text-white bg-red-600 rounded-full right-2 top-1">
                          {notifyCount > 9 ? "9+" : notifyCount}
                        </div>
                      )}
                    </button>
                  </div>
                </Dropdown>
              </div> */}

              {/* {(selectedLocation?.name === "Select A Location" || isChatView) && */}
              <div className="flex flex-col justify-center cursor-pointer">
                <button
                  type="button"
                  className="relative inline-flex items-center py-2 px-1 text-sm font-medium text-center text-white cursor-pointer"
                  onClick={HandleChatList}
                >
                  <img src={chatListIcon} alt="chat-logo" width={36} />
                </button>
              </div>
              {/* } */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
