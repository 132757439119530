import React from "react";
import "./FormSelect.scss";
import Select from "react-select";
import { Input, Form } from "antd";
import { instagramIcon } from "../../../assets/images";

const FormSelect = ({
  options,
  name,
  label,
  description,
  type = "text",
  placeholder,
  defaultValue,
  value,
  addonBefore,
  onChange,
  error,
  darmo,
  className,
  classNames,
  disabledEntity,
  declineClass,
  ...rest
}) => {
  // Custom styles for react-select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: darmo ? "white" : "slate-800",
      color: darmo ? "gray-900" : "white",
      borderColor: state.isFocused ? "indigo-600" : "warmgray-300",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: darmo ? "gray-900" : "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#594a9e"
        : state.isFocused
        ? "#E5E7EB"
        : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
  };

  return (
    <div
      className={`${type === "add-job" ? "w-full" : "mb-2"} ${declineClass}`}
    >
      <label
        className={`${
          type === "add-job" || type === "entity-type"
            ? "mb-1"
            : type === "go"
            ? "font-bold"
            : ""
        } block font-bold title ${classNames} ${
          darmo ? "text-gray-900" : "text-white"
        }`}
      >
        {label}
      </label>
      <Form.Item
        name={name}
        rules={[{ required: true, message: "Required!" }]}
        noStyle
      >
        <div
          className={`flex border border-warmgray-300 rounded-md ${
            (label === "Entity Type" || label === "Select Entity") &&
            type !== "entity-type" &&
            "mt-2"
          }`}
        >
          {label === "Require Instagram" && (
            <div className="border-l border-y my-auto p-2">
              <img src={instagramIcon} className="w-5 h-5" />
            </div>
          )}

          <Select
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            isSearchable={false}
            defaultValue={defaultValue}
            value={value}
            isDisabled={disabledEntity}
            styles={customStyles} // Apply custom styles
            className={`${
              darmo
                ? defaultValue === ""
                  ? "bg-white text-gray-400"
                  : "bg-white text-gray-900"
                : "bg-slate-800 text-white"
            } py-[0.5px] block cursor-pointer w-full rounded-md ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 ${className}`}
            {...rest}
          />
        </div>
        {error ? (
          <div className="text-red-400 text-xs font-bold mt-2">{error}</div>
        ) : (
          ""
        )}
      </Form.Item>
    </div>
  );
};

export default FormSelect;
