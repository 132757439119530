import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutesList from "./routes";
import "./App.css";
import Login from "./pages/auth/Login";
import RouteGuard from "./components/RouteGuard";
import Dashboard from "./pages/Dashboard";
import BusinessDashboard from "./pages/business/BusinessDashboard";
import Register from "./pages/auth/Register";
import RegionalManagerDashboard from "./pages/RegionalManagerDashboard";
import BusinessOwnerDashboard from "./pages/BusinessOwnerDashboard";
import {
  selectRoles,
  selectUser,
  selectSelectedLocation,
  setRoleName,
  setChatListOpen,
  setChatListExpand,
  setFixedChat,
} from "./store/authSlice";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Settings from "./pages/admin/Settings/Settings";
import ChatList from "./components/organisms/Chat/ChatList";
import { setNavigateFunction } from "./api/api";
import Payment from "./api/Payment";
import JobPosition from "./api/JobPosition";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);
  const selectedLocation = useSelector(selectSelectedLocation);
  const role = roles.length > 0 ? roles[0] : "";
  const shouldRenderChatList =
    location.pathname !== "/login" &&
    location.pathname !== "/subscribe" &&
    location.pathname !== "/register" &&
    location.pathname !== "/forgot-password" &&
    location.pathname !== "/password-reset" &&
    !location.pathname.startsWith("/password-reset/") &&
    !location.pathname.startsWith("/file-upload") &&
    location.pathname !== "/heyhire" &&
    location.pathname !== "/401" &&
    !location.pathname.startsWith("/easy-login") &&
    !location.pathname.startsWith("/easy-registration") &&
    !location.pathname.startsWith("/easy-confirmation") &&
    !location.pathname.startsWith("/heyhire/") &&
    location.pathname !== "/subscription" &&
    location.pathname !== "/plans";

  const DashboardCont = () => {
    const isEnterprise = user?.company?.is_enterprise === 1;
    if (role === "super-user") {
      return <Dashboard />;
    } else if (role === "turnkey_admin") {
      return <Settings />;
    } else if (role === "regional_manager" && !selectedLocation?.id) {
      return <RegionalManagerDashboard />;
    } else if (role === "owner" && isEnterprise && !selectedLocation?.id) {
      return <BusinessOwnerDashboard />;
    } else {
      return <BusinessDashboard />;
    }
  };

  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  const checkSubscriptionInfo = async () => {
    const info = await Payment.getStripeCustomerID(
      user?.company_id,
      user?.location_id
    );

    if (info) {
      const response = await Payment.getSubscriptionDetailsFromCustomerId(
        info?.data?.stripe_customer?.stripe_customer_id
      );
      if (response?.data?.length === 1) {
        console.log(response?.data[0]);
      } else if (response?.data?.length > 1) {
        if (selectedLocation?.name === "Select A Location") {
          console.log(response?.data[0]);
        } else {
          const matchingLocation = response?.data?.find(
            (item) => item.location_name === selectedLocation.name
          );
          if (matchingLocation) {
            console.log(matchingLocation);
          }
        }
      }
    }
  };

  const checkJobCreated = async () => {
    const info = await JobPosition.checkJobCreated();
    if (!info?.data) {
      navigate("/add-sub-user");
    }
  };

  useEffect(() => {
    if (user) {
      // checkSubscriptionInfo();
      checkJobCreated();
    }
  }, [user]);

  const handleResize = () => {
    if (window.innerWidth >= 1536) {
      dispatch(setChatListOpen(true));
      dispatch(setChatListExpand(true));
      dispatch(setFixedChat(true));
    } else {
      dispatch(setFixedChat(false));
      dispatch(setChatListOpen(false));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/login"
          element={
            <RouteGuard guest={true}>
              <Login />
            </RouteGuard>
          }
        />
        <Route
          path={"/plans"}
          element={
            <RouteGuard guest={true}>
              <Register />
            </RouteGuard>
          }
        />
        <Route
          path={"/register"}
          element={
            <RouteGuard guest={true}>
              <Register />
            </RouteGuard>
          }
        />
        <Route
          path={"/plans/:plan_type"}
          element={
            <RouteGuard guest={true}>
              <Register />
            </RouteGuard>
          }
        />
        <Route
          path={"/forgot-password"}
          element={
            <RouteGuard guest={true}>
              <ForgotPassword />
            </RouteGuard>
          }
        />
        <Route
          path={"/password-reset/:token"}
          element={
            <RouteGuard guest={true}>
              <ResetPassword />
            </RouteGuard>
          }
        />
        <Route
          path="/dashboard"
          element={
            <RouteGuard auth={true}>
              <DashboardCont />
            </RouteGuard>
          }
        />
        <Route
          path="/rm/dashboard"
          element={
            <RouteGuard auth={true}>
              <RegionalManagerDashboard />
            </RouteGuard>
          }
        />
        <Route
          path="/bo/dashboard"
          element={
            <RouteGuard auth={true}>
              <BusinessOwnerDashboard />
            </RouteGuard>
          }
        />

        {RoutesList.map((ro, i) => (
          <Route
            exact
            key={i}
            path={ro.path}
            element={
              ro.auth ? (
                <RouteGuard auth={ro.auth}>{ro.element}</RouteGuard>
              ) : (
                <>{ro.element}</>
              )
            }
          />
        ))}

        <Route
          path="*"
          element={
            <RouteGuard auth={true}>
              <Navigate to="/dashboard" />
            </RouteGuard>
          }
        />
      </Routes>
      {/* {shouldRenderChatList && role !== "super-user" && (selectedLocation?.name === "Select A Location" ? true : isChatView) && <ChatList />} */}
      {shouldRenderChatList && role !== "super-user" && <ChatList />}
      <ToastContainer />
    </>
  );
}

export default App;
