import { Input, Form } from "antd";
import React from "react";

const FormInput = ({
  id,
  name,
  label,
  type = "text",
  placeholder,
  useForm = true,
  defaultValue,
  darmo,
  ...rest
}) => {
  return (
    <>
      <label
        className={`title font-bold ${darmo ? "text-gray-900" : "text-white"}`}
      >
        {label}
      </label>
      <Form.Item id={id} name={name} noStyle shouldUpdate>
        <Input
          id={id}
          defaultValue={defaultValue}
          name={name}
          {...rest}
          className={`${label === "QR Name" && "mt-2"} ${
            darmo
              ? "text-gray-900 placeholder:text-gray-400  focus:ring-indigo-600 bg-white"
              : "text-white placeholder:text-gray-300  focus:ring-white bg-slate-800  "
          } block w-full rounded-md border-0 p-2 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset text-[14px] sm:leading-6`}
          type={type}
          placeholder={placeholder}
        />
      </Form.Item>
    </>
  );
};

export default FormInput;
