import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import ScaleLoader from "react-spinners/ScaleLoader";
import QRCodes from "../../api/Qrcode";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { DashboardLayout } from "../../components/organisms/Layout";
import { Button, Form, Space } from "antd";
import { DesignPoster, uploadFileIcon } from "../../assets/images";
import ColorSelect from "../../components/atoms/ColorSelect";
import FormSelect from "../../components/atoms/FormSelect";
import { selectSelectedLocation, selectUser } from "../../store/authSlice";
import ReactImageUploading from "react-images-uploading";

const BannerTypes = [
  { value: "SocialPost", label: "Social Media" },
  { value: "StoreBanner", label: "Store Banner" },
];
const CreatePoster = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const selectedLocation = useSelector(selectSelectedLocation);
  const user = useSelector(selectUser);

  const editMode = location.pathname.search("edit") !== -1;
  const [bgColor, setBgColor] = useState("#fff");
  const [textColor, setTextColor] = useState("#000");
  const [footerBgColor, setFooterBgColor] = useState("#000");
  const [ringColor, setRingColor] = useState("#000");
  const [footerTextColor, setFooterTextColor] = useState("#fff");
  const [ringPicker, setRingPicker] = useState(false);
  const [footerTextPicker, setFooterTextPicker] = useState(false);
  const [footerBgPicker, setFooterBgPicker] = useState(false);
  const [bgPicker, setBgPicker] = useState(false);
  const [textPicker, setTextPicker] = useState(false);
  const [designPoster, setDesignPoster] = useState(DesignPoster);
  const [qrList, setQrList] = useState([]);
  const [pdfPoster, setPDFPoster] = useState(null);
  const [downloadCheck, setDownloadCheck] = useState(false);
  const [qrId, setQrId] = useState("");
  const [qrIdError, setQrIdError] = useState(false);
  const [posterId, setPosterId] = useState();
  const [loading, setLoading] = useState(false);
  const [logoType, setLogoType] = useState("");
  const [logoTypeError, setLogoTypeError] = useState(false);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [previewImage, setPreviewImage] = useState("/images/register_logo.svg");
  const [logoIcon, setLogoIcon] = useState(null);
  const [logoError, setLogoError] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [location_id, setLocationId] = useState(
    selectedLocation?.id ? selectedLocation?.id : user?.location?.id
  );
  const posterObj = location?.state?.qrcode;
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (editMode) {
      populatePoster();
    }
    loadQRList();
  }, []);

  useEffect(() => {
    if (editMode) {
      document.title = "HeyHire Portal-Edit Poster";
    } else {
      document.title = "HeyHire Portal-Create New Poster";
    }
  }, []);

  const loadQRList = () => {
    QRCodes.getAll()
      .then((response) => {
        let qrData = response.data.data;
        const qrBannerTypes = qrData.map((qr) => {
          return {
            value: qr.id,
            label: qr.name,
          };
        });

        setQrList(qrBannerTypes);
      })
      .catch((error) => {
        console.log("loadQRList -> error", error);
      });
  };

  const generatePoster = () => {
    if (validations()) {
      setLoading(true);
      let tempBody = {};
      if (posterId) {
        tempBody = {
          location_id: location_id,
          template: logoType,
          poster_id: posterId,
          logo_url: logoIcon,
          colors: {
            text: textColor,
            footer_text: footerTextColor,
            footer_background: footerBgColor,
            ring: ringColor,
            background_color: bgColor,
          },
          qr_id: qrId,
        };
      } else {
        tempBody = {
          location_id: location_id,
          template: logoType,
          logo_url: logoIcon,
          colors: {
            text: textColor,
            footer_text: footerTextColor,
            footer_background: footerBgColor,
            ring: ringColor,
            background_color: bgColor,
          },
          qr_id: qrId,
        };
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(tempBody),
      };
      fetch(process.env.REACT_APP_POSTER_URL, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setPosterId(data?.data?.id);
          setDesignPoster(data.data.jpg);
          setPDFPoster(data.data.pdf);
          setDownloadCheck(true);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error?.response?.status !== 403) {
            toast.error("Something went wrong. Please try again.");
          }
        });
    }
  };

  const uploadLogo = (_file) => {
    setUploadingLogo(true);
    setLogoError(false);
    QRCodes.uploadLogo(_file[0].file)
      .then((response) => {
        setLogoIcon(response?.data?.data?.logo_url);
        // setFileName(_file?.name);
        setUploadingLogo(false);
        toast.success("Logo Uploaded Successfully");
      })
      .catch((error) => {
        setUploadingLogo(false);
        if (error?.response?.status !== 403) {
          toast.error("Something went wrong. Please try again.");
        }
      });
  };

  function handleImageChange(e) {
    setUploadingLogo(false);
    let file = e.target.files[0];
    uploadLogo(file);
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const populatePoster = () => {
    setLoading(true);
    const _tempColors = JSON.parse(posterObj.payload);
    setBgColor(_tempColors?.colors?.background_color);
    setTextColor(_tempColors?.colors?.text);
    setFooterBgColor(_tempColors?.colors?.footer_background);
    setFooterTextColor(_tempColors?.colors?.footer_text);
    setRingColor(_tempColors?.colors?.ring);
    setLogoIcon(_tempColors?.logo_url);
    setPreviewImage(_tempColors?.logo_url);
    setQrId(_tempColors?.qr_id);
    setPosterId(posterObj?.id);
    setLogoType(_tempColors?.template);
    setLocationId(_tempColors?.location_id);
    setDesignPoster(posterObj?.jpg);
    setLoading(false);
  };

  const clearPoster = () => {
    setBgColor("#fff");
    setTextColor("#000");
    setFooterBgColor("#000");
    setFooterTextColor("#fff");
    setRingColor("#000");
    setLogoIcon(null);
    setPreviewImage("/images/register_logo.svg");
    setDesignPoster(DesignPoster);
    setDownloadCheck(false);
    setLoading(false);
  };

  const downloadPoster = () => {
    generatePoster();
    saveAs(pdfPoster, "poster.pdf");
  };

  const validations = () => {
    setLogoError(false);
    setLogoTypeError(false);
    setQrIdError(false);

    const isLogoValid = !!logoIcon;
    const isLogoTypeValid = logoType !== "";
    const isQrIdValid = qrId !== "";

    setLogoError(!isLogoValid);
    setLogoTypeError(!isLogoTypeValid);
    setQrIdError(!isQrIdValid);

    return isLogoValid && isLogoTypeValid && isQrIdValid;
  };

  return (
    <DashboardLayout darmo={true}>
      <div className="overflow-x-auto overflow-hidden w-full max-w-7xl pb-12 mt-4">
        <div className="flex justify-between flex-col">
          <h2 className="font-bold text-2xl">
            {editMode ? t("poster.edit-poster") : "Design Your Poster"}
          </h2>
          <hr className="w-full border border-primary-100 mt-1" />
        </div>
        <div className="flex flex-col md:flex-row w-full justify-between">
          <div className="mt-6 w-full md:w-1/2 xl:w-2/5 2xl:w-1/3 md:mr-8">
            <div className="mb-6">
              <p className="block mb-2 font-bold title text-warmgray-900">
                Upload Logo
              </p>

              <ReactImageUploading
                value={images}
                onChange={(imageList, index) => {
                  setImages(imageList);
                  uploadLogo(imageList);
                }}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <div className="flex">
                    <div className="h-28 w-28 bg-warmgray-200 self-center flex justify-center">
                      {imageList && imageList.length > 0 ? (
                        <img
                          className="object-cover h-28 w-28 bg-warmgray-200 self-center"
                          src={imageList[0].dataURL}
                        />
                      ) : (
                        <i
                          className="text-white text-3xl self-center fa fa-plus cursor-pointer"
                          onClick={onImageUpload}
                        />
                      )}
                    </div>
                    <button
                      className="ml-4 my-auto flex justify-center items-center rounded-full bg-primary-500 px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                      onClick={onImageUpload}
                      disabled={uploadingLogo}
                    >
                      Upload Logo
                    </button>
                  </div>
                )}
              </ReactImageUploading>
              {logoError && (
                <p className="text-sm text-red-400 font-bold mt-2">
                  Upload Logo Before Generating Poster
                </p>
              )}
            </div>

            <div className="mb-6">
              <p className="block mb-2 font-bold title text-warmgray-900">
                Select Poster Type
              </p>
              <div className="mx-0.5">
                <FormSelect
                  name="banner_type"
                  onChange={(value) => {
                    setLogoType(value.value);
                  }}
                  options={BannerTypes}
                  darmo={true}
                  defaultValue={logoType}
                  placeholder={BannerTypes[parseInt(logoType) - 1]?.label}
                />
              </div>
              {logoTypeError && (
                <p className="text-sm text-red-400 font-bold mt-1">
                  Poster Type is Required
                </p>
              )}
            </div>

            <div className="mb-6">
              <p className="block mb-2 font-bold title text-warmgray-900">
                Select QR
              </p>
              <div className="mx-0.5">
                <FormSelect
                  name="qr_type"
                  onChange={(value) => {
                    setQrId(value.value);
                  }}
                  options={qrList}
                  darmo={true}
                  defaultValue={qrId}
                  placeholder={qrList[parseInt(qrId) - 1]?.label}
                />
              </div>
              {qrIdError && (
                <p className="text-sm text-red-400 font-bold mt-1">
                  QR Type is Required
                </p>
              )}
            </div>

            <div className="mb-6 mt-0 md:mt-12">
              <ColorSelect
                onClickDisplay={() => {
                  setRingPicker(false);
                  setFooterTextPicker(false);
                  setFooterBgPicker(false);
                  setBgPicker(!bgPicker);
                  setTextPicker(false);
                }}
                display={bgPicker}
                name="bg"
                label="Choose Background Color"
                defaultValue={bgColor}
                value={bgColor}
                onChange={(e) => {
                  setBgColor(e.hex);
                }}
              />
            </div>

            <div className="mb-6">
              <ColorSelect
                onClickDisplay={() => {
                  setRingPicker(!ringPicker);
                  setFooterTextPicker(false);
                  setFooterBgPicker(false);
                  setBgPicker(false);
                  setTextPicker(false);
                }}
                display={ringPicker}
                name="bg"
                label="Choose QR Bubble Color"
                defaultValue={ringColor}
                value={ringColor}
                onChange={(e) => {
                  setRingColor(e.hex);
                }}
              />
            </div>

            <div className="mb-6">
              <ColorSelect
                onClickDisplay={() => {
                  setRingPicker(false);
                  setFooterTextPicker(false);
                  setFooterBgPicker(false);
                  setBgPicker(false);
                  setTextPicker(!textPicker);
                }}
                display={textPicker}
                name="text"
                label="Choose Text Color"
                defaultValue={textColor}
                value={textColor}
                onChange={(e) => {
                  setTextColor(e.hex);
                }}
              />
            </div>

            <div className="mb-6">
              <ColorSelect
                onClickDisplay={() => {
                  setRingPicker(false);
                  setFooterTextPicker(false);
                  setFooterBgPicker(!footerBgPicker);
                  setBgPicker(false);
                  setTextPicker(false);
                }}
                display={footerBgPicker}
                name="footer_bg"
                label="Choose Footer Background Color"
                defaultValue={footerBgColor}
                value={footerBgColor}
                onChange={(e) => {
                  setFooterBgColor(e.hex);
                }}
              />
            </div>

            <div className="">
              <ColorSelect
                onClickDisplay={() => {
                  setRingPicker(false);
                  setFooterTextPicker(!footerTextPicker);
                  setFooterBgPicker(false);
                  setBgPicker(false);
                  setTextPicker(false);
                }}
                display={footerTextPicker}
                name="footer_text_color"
                label="Choose Footer Text Color"
                defaultValue={footerTextColor}
                //disabled={previewImage === "/images/register_logo.svg"}
                value={footerTextColor}
                onChange={(e) => {
                  setFooterTextColor(e.hex);
                }}
              />
            </div>

            <div className="flex mt-8 justify-center">
              <button
                className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                onClick={() => downloadPoster()}
                disabled={!downloadCheck && !editMode}
              >
                {editMode && !downloadCheck ? "Download" : "Save & Download"}
              </button>
            </div>
          </div>

          <div className="mt-6 w-full md:w-1/2 xl:w-2/5 2xl:w-1/3 flex flex-col items-end justify-end">
            <div>
              <p className="block mb-2 font-bold text-sm md:text-base text-warmgray-900">
                Preview
              </p>
              <div className="flex justify-center items-center">
                {loading ? (
                  <ScaleLoader
                    color={"#594A9E"}
                    loading={true}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <img
                    src={designPoster}
                    alt="logo"
                    className="p-3 shadow-lg"
                  />
                )}
              </div>
              <div className="flex flex-col mt-8 items-center justify-center">
                <button
                  className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                  onClick={() => generatePoster()}
                  disabled={loading}
                >
                  Generate Poster
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CreatePoster;
