import React from "react";
import "react-loading-skeleton/dist/skeleton.css";

const ChatLoading = () => {
  return (
    <li className="col-span-1 flex divide-y divide-gray-300 rounded-lg animate-pulse px-1">
      <div className="p-4 w-full flex items-center">
        <div className="flex-shrink-0">
          <div className="rounded-full bg-gray-300 h-12 w-12"></div>
        </div>

        <div className="flex flex-col justify-center flex-grow ml-4 space-y-3">
          <div className="h-2 bg-gray-300 rounded w-3/4"></div>
          <div className="h-2 bg-gray-300 rounded w-1/2"></div>
        </div>
      </div>
    </li>
  );
};

export default ChatLoading;
