import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    init: false,
    loggedIn: false,
    user: null,
    token: null,
    permissions: [],
    roles: [],
    accessible_entities: [],
    enterprise: false,
    selectedLocation: null,
    background: true,
    sidebar: true,
    proprietor: null,
    chatList: true,
    isChatListExpand: true,
    selectedPersons: [],
    chats: [],
    isChatLoading: false,
    isConversationLoading: true,
    visiblePersonsArray: [],
    minimisedPersonsArray: [],
    deleteChats: false,
    addNewChat: false,
    preCannedMessages: [],
    conversations: [],
    selectedConversation: [],
    initialChatFetch: false,
    messages: [],
    chatToken: null,
    refreshToken: false,
    chatApplicant: null,
    notificationApplicant: null,
    clientTwo: null,
    newMessage: false,
    readNotify: false,
    notificationCount: 0,
    unreadData: [],
    newSubUser: false,
    chatWindowLoader: false,
    docs: [],
    jobPositionTitle: null,
    jobApplicantTitle: null,
    stripeCustomerId: null,
    refreshFeature: false,
    refreshRole: false,
    shiftsArray: [],
    nextShiftPage: false,
    eventDetails: null,
    isEventPage: false,
    roleName: null,
    locations: [],
    initialAlertFetch: false,
    notificationAlertData: null,
    isAnalytics: false,
    isFixedChat: false,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.permissions = action.payload.permissions;
      state.roles = action.payload.roles;
      state.trial_period = action.payload.trial_period;
      state.notify_message = action.payload.notify_message;
      state.accessible_entities = action.payload.accessible_entities;
      state.enterprise = Boolean(action.payload.enterprise);
      state.loggedIn = true;
      state.init = true;
    },
    logout: (state) => {
      state.user = null;
      state.isAnalytics = false;
      state.selectedLocation = null;
      state.loggedIn = false;
      state.init = true;
      state.selectedPersons = [];
      state.visiblePersonsArray = [];
      state.minimisedPersonsArray = [];
      state.activeParticipants = [];
      state.isConversationLoading = true;
      state.initialAlertFetch = false;
      state.initialA = false;
      state.chatList = true;
      state.chats = [];
      localStorage.removeItem("token");
      localStorage.removeItem("selectedLocation");
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.init = true;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setBackgroundcolor: (state, action) => {
      state.background = action.payload;
    },
    setSidebarOpen: (state, action) => {
      state.sidebar = action.payload;
    },
    setProprietor: (state, action) => {
      state.proprietor = action.payload;
    },
    setChatListOpen: (state, action) => {
      state.chatList = action.payload;
    },
    setChatListExpand: (state, action) => {
      state.isChatListExpand = action.payload;
    },
    setSelectedPersons: (state, action) => {
      state.selectedPersons = action.payload;
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setChatLoading: (state, action) => {
      state.isChatLoading = action.payload;
    },
    setConversationLoading: (state, action) => {
      state.isConversationLoading = action.payload;
    },
    setVisiblePersons: (state, action) => {
      state.visiblePersonsArray = action.payload;
    },
    setMinimizedPersons: (state, action) => {
      state.minimisedPersonsArray = action.payload;
    },
    setDeleteChat: (state, action) => {
      state.deleteChats = action.payload;
    },
    setAddNewChat: (state, action) => {
      state.addNewChat = action.payload;
    },
    setPreCannedMessages: (state, action) => {
      state.preCannedMessages = action.payload;
    },
    setConversations: (state, action) => {
      state.conversations = action.payload;
    },
    setInitialChatFetch: (state, action) => {
      state.initialChatFetch = action.payload;
    },
    setInitialAlertFetch: (state, action) => {
      state.initialAlertFetch = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setChatToken: (state, action) => {
      state.chatToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
    setChatApplicant: (state, action) => {
      state.chatApplicant = action.payload;
    },
    setNotificationApplicant: (state, action) => {
      state.notificationApplicant = action.payload;
    },
    setApplicantData: (state, action) => {
      state.applicantData = action.payload;
    },
    setClientTwo: (state, action) => {
      state.clientTwo = action.payload;
    },
    setNewMessage: (state, action) => {
      state.newMessage = action.payload;
    },
    setReadNotification: (state, action) => {
      state.readNotifify = action.payload;
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setUnreadData: (state, action) => {
      state.unreadData = action.payload;
    },
    setNewSubUser: (state, action) => {
      state.newSubUser = action.payload;
    },
    setChatWindowLoader: (state, action) => {
      state.chatWindowLoader = action.payload;
    },
    setDocs: (state, action) => {
      state.docs = action.payload;
    },
    setJobPositionTitle: (state, action) => {
      state.jobPositionTitle = action.payload;
    },
    setJobApplicantTitle: (state, action) => {
      state.jobApplicantTitle = action.payload;
    },
    setStripeCustomerId: (state, action) => {
      state.stripeCustomerId = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setRefreshFeature: (state, action) => {
      state.refreshFeature = action.payload;
    },
    setRefreshRole: (state, action) => {
      state.refreshRole = action.payload;
    },
    setNextShiftPage: (state, action) => {
      state.nextShiftPage = action.payload;
    },
    setShiftsArray: (state, action) => {
      state.shiftsArray = action.payload;
    },
    setEventDetails: (state, action) => {
      state.eventDetails = action.payload;
    },
    setEventPage: (state, action) => {
      state.isEventPage = action.payload;
    },
    setRoleName: (state, action) => {
      state.roleName = action.payload;
    },
    setAllLocations: (state, action) => {
      state.locations = action.payload;
    },
    setNotificationData: (state, action) => {
      state.notificationAlertData = action.payload;
    },
    setAnalytics: (state, action) => {
      state.isAnalytics = action.payload;
    },
    setFixedChat: (state, action) => {
      state.isFixedChat = action.payload;
    },
  },
});

export const selectToken = (state) => state.auth.token;
export const selectLoggedIn = (state) => state.auth.loggedIn;
export const selectUser = (state) => state.auth.user;
export const selectInit = (state) => state.auth.init;
export const selectRoles = (state) => state.auth.roles;
export const selectPermissions = (state) => state.auth.permissions;
export const selectSubscriptionTrialStatus = (state) => state.auth.trial_period;
export const notifyMessage = (state) => state.auth.notify_message;
export const darkmode = (state) => state.auth.background;
export const sidebar = (state) => state.auth.sidebar;
export const chatList = (state) => state.auth.chatList;
export const isChatListExpand = (state) => state.auth.isChatListExpand;
export const selectAccessibleEntities = (state) =>
  state.auth.accessible_entities;
export const selectSelectedLocation = (state) => state.auth.selectedLocation;
export const selectedPersons = (state) => state.auth.selectedPersons;
export const selectProprietor = (state) => state.auth.proprietor;
export const selectChats = (state) => state.auth.chats;
export const chatsLoading = (state) => state.auth.isChatLoading;
export const conversationsLoading = (state) => state.auth.isConversationLoading;
export const visiblePersonsArray = (state) => state.auth.visiblePersonsArray;
export const minimisedPersonsArray = (state) =>
  state.auth.minimisedPersonsArray;
export const deleteChats = (state) => state.auth.deleteChats;
export const addNewChat = (state) => state.auth.addNewChat;
export const preCannedMessages = (state) => state.auth.preCannedMessages;
export const conversations = (state) => state.auth.conversations;
export const initialChatFetch = (state) => state.auth.initialChatFetch;
export const initialAlertFetch = (state) => state.auth.initialAlertFetch;
export const messages = (state) => state.auth.messages;
export const chatToken = (state) => state.auth.chatToken;
export const refreshToken = (state) => state.auth.refreshToken;
export const selectedConversation = (state) => state.auth.selectedConversation;
export const chatApplicant = (state) => state.auth.chatApplicant;
export const notificationApplicant = (state) =>
  state.auth.notificationApplicant;
export const applicantData = (state) => state.auth.applicantData;
export const clientTwo = (state) => state.auth.clientTwo;
export const newMessage = (state) => state.auth.newMessage;
export const readNotify = (state) => state.auth.readNotify;
export const notificationCount = (state) => state.auth.notificationCount;
export const unreadData = (state) => state.auth.unreadData;
export const newSubUser = (state) => state.auth.newSubUser;
export const chatWindowLoader = (state) => state.auth.chatWindowLoader;
export const docs = (state) => state.auth.docs;
export const jobPositionTitle = (state) => state.auth.jobPositionTitle;
export const jobApplicantTitle = (state) => state.auth.jobApplicantTitle;
export const stripeCustomerId = (state) => state.auth.stripeCustomerId;
export const refreshFeature = (state) => state.auth.refreshFeature;
export const refreshRole = (state) => state.auth.refreshRole;
export const nextShiftPage = (state) => state.auth.nextShiftPage;
export const shiftsArray = (state) => state.auth.shiftsArray;
export const eventDetails = (state) => state.auth.eventDetails;
export const isEventPage = (state) => state.auth.isEventPage;
export const roleName = (state) => state.auth.roleName;
export const locations = (state) => state.auth.locations;
export const notificationAlertData = (state) =>
  state.auth.notificationAlertData;
export const isAnalytics = (state) => state.auth.isAnalytics;
export const isFixedChat = (state) => state.auth.isFixedChat;

export const {
  login,
  logout,
  setUser,
  setSelectedLocation,
  setBackgroundcolor,
  setSidebarOpen,
  setProprietor,
  setChatListOpen,
  setChatListExpand,
  setSelectedPersons,
  setChats,
  setChatLoading,
  setConversationLoading,
  setVisiblePersons,
  setMinimizedPersons,
  setDeleteChat,
  setAddNewChat,
  setPreCannedMessages,
  setConversations,
  setInitialChatFetch,
  setInitialAlertFetch,
  setMessages,
  setChatToken,
  setRefreshToken,
  setSelectedConversation,
  setChatApplicant,
  setApplicantData,
  setClientTwo,
  setNewMessage,
  setReadNotification,
  setNotificationCount,
  setUnreadData,
  setNewSubUser,
  setNotificationApplicant,
  setChatWindowLoader,
  setDocs,
  setJobPositionTitle,
  setJobApplicantTitle,
  setStripeCustomerId,
  setRefreshFeature,
  setRefreshRole,
  setNextShiftPage,
  setShiftsArray,
  setEventDetails,
  setEventPage,
  setRoleName,
  setAllLocations,
  setNotificationData,
  setAnalytics,
  setFixedChat,
} = authSlice.actions;

export default authSlice.reducer;
