import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DashboardLayout } from "../components/organisms/Layout";
import DashboardApi from "../api/Dashboard";
import { defaultUserImage } from "../assets/images";
import Table from "../components/atoms/Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  setInitialChatFetch,
  setMinimizedPersons,
  setSelectedLocation,
  setSelectedPersons,
  setVisiblePersons,
  locations,
} from "../store/authSlice";
import { useNavigate } from "react-router-dom";
import User from "../api/User";
import { encodeIds } from "../utils";
import TableLoading from "../components/Loading/TableLoading";

const BusinessOwnerDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allLocations = useSelector(locations);
  const [recentLogins, setRecentLogins] = useState([]);
  const [recentApplications, setRecentApplications] = useState([]);
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [isLoadingApplications, setLoadingApplications] = useState(false);
  const [isLoadingLogins, setLoadingLogins] = useState(false);

  const changeLocation = async (id) => {
    try {
      await User.updateLocation(id);
      setShowNotification({
        show: true,
        type: "success",
        message: "Location Changed!",
      });
    } catch (error) {
      setShowNotification({
        show: true,
        type: "error",
        message: "Error Occurred during Location switching. Try Again!",
      });
    }
  };

  const loadRecentApplications = async () => {
    await DashboardApi.getRecentApplications()
      .then((response) => {
        setRecentApplications(response.data.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadingApplications(true));
  };

  const loadRecentLogins = async () => {
    await DashboardApi.getRecentLogins()
      .then((response) => {
        const userData = response.data.data.filter(
          (e) =>
            e.roles[0]?.name !== "owner" && e?.roles[0]?.name !== "super-user"
        );
        setRecentLogins(userData);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadingLogins(true));
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Dashboard";
    loadRecentApplications();
    loadRecentLogins();
  }, []);

  return (
    <DashboardLayout darmo={true}>
      <div className="w-full">
        <div className="overflow-hidden">
          {!isLoadingApplications ? (
            <TableLoading />
          ) : (
            <Table
              title={t("dashboard.latest-application-submissions")}
              headers={
                recentApplications?.length > 0
                  ? [
                      t("common.applicants-name"),
                      t("common.applied-for"),
                      t("common.location"),
                    ]
                  : ["No Applicants Yet"]
              }
              data={recentApplications?.slice(0, 5).map((item, index) => (
                <tr key={index}>
                  <td
                    className="whitespace-nowrap py-3 pr-3 text-sm md:text-base pl-1 w-1/3 cursor-pointer"
                    onClick={() => {
                      const filteredLocations = allLocations?.filter(
                        (location) => location?.id === item?.location_id
                      );
                      if (filteredLocations[0]) {
                        dispatch(setSelectedLocation(filteredLocations[0]));
                        localStorage.setItem(
                          "selectedLocation",
                          JSON.stringify(filteredLocations[0])
                        );
                        changeLocation(filteredLocations[0]?.id);
                        navigate(
                          `/business/applicant/${encodeIds(
                            item.job_id
                          )}/${encodeIds(item.job_seeker_id)}`
                        );
                        dispatch(setInitialChatFetch(false));
                        dispatch(setVisiblePersons([]));
                        dispatch(setMinimizedPersons([]));
                        dispatch(setSelectedPersons([]));
                      }
                    }}
                  >
                    <div className="flex items-center">
                      <div className="h-9 w-9 flex-shrink-0 transform transition duration-200 ease-in-out hover:scale-110">
                        <img
                          alt=""
                          src={
                            item?.seeker?.photo?.thumb_url || defaultUserImage
                          }
                          className="h-9 w-9 rounded-full"
                        />
                      </div>
                      <div className="ml-2 md:ml-4">
                        <div className="font-medium text-gray-900 hover:underline">
                          {item?.seeker?.first_name} {item?.seeker?.last_name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500 w-1/3">
                    <div className="text-gray-900">{item?.position?.title}</div>
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-500 w-1/3">
                    <div className="text-gray-900">{item?.location?.name}</div>
                  </td>
                </tr>
              ))}
            />
          )}
        </div>

        <hr className="my-10 border border-warmgray-200" />

        <div className="pb-12 overflow-hidden">
          {!isLoadingLogins ? (
            <TableLoading />
          ) : (
            <Table
              title={"Recent User Login"}
              headers={
                recentLogins?.length > 0
                  ? [t("common.full-name"), "Time", t("common.location")]
                  : ["No Data Available"]
              }
              data={recentLogins?.slice(0, 5).map((item, index) => (
                <tr key={index}>
                  <td className="whitespace-nowrap py-4 pr-3 text-sm md:text-base w-1/3">
                    <div className="text-gray-900">
                      {item?.first_name} {item?.last_name}
                    </div>
                  </td>
                  <td className="whitespace-nowrap pr-3 py-4 text-sm md:text-base text-gray-500 w-1/3">
                    <div className="text-gray-900">
                      {item?.last_login || "This user has not logged in yet"}
                    </div>
                  </td>
                  <td className="whitespace-nowrap pr-3 py-4 text-sm md:text-base text-gray-500 w-1/3">
                    <div className="text-gray-900">{item?.location?.name}</div>
                  </td>
                </tr>
              ))}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BusinessOwnerDashboard;
