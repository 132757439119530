import { Divider, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { settingsIcon } from "../assets/images";

const TableActionDropdown = ({
  onClickEdit,
  onClickDelete,
  onClickArchive,
  archived_at,
  name,
}) => {
  const { t } = useTranslation();
  const menu = (
    <div className="flex flex-col bg-white shadow-lg rounded-xl border border-warmgray-200">
      {onClickEdit && (
        <button
          className="block w-full px-4 py-2 hover:bg-primary-500 hover:text-white"
          onClick={onClickEdit}
        >
          {t("common.edit")}
        </button>
      )}
      {onClickDelete && (
        <>
          <Divider className="m-0" />
          <button
            className="block w-full px-4 py-2 hover:bg-primary-500 hover:text-white"
            onClick={onClickDelete}
          >
            {t("common.delete")}
          </button>
        </>
      )}
      {onClickArchive && (
        <button
          className="block w-full px-4 py-2 hover:bg-primary-500 hover:text-white rounded-xl"
          onClick={onClickArchive}
        >
          {archived_at !== null ? "Restore the Position" : "Archive Position"}
        </button>
      )}
    </div>
  );
  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger="click">
      <i
        className={`text-gray-600 hover:text-indigo-900 far fa-ellipsis-h ${
          name === "jobOpening" &&
          "border hover:border-gray-400 rounded-full p-1"
        }`}
      />
    </Dropdown>
  );
};

export const DropActionDropdown = ({
  className,
  menus,
  type,
  icon = "far fa-ellipsis-v",
  iconColor,
  iconSize,
  image,
}) => {
  const menu = (
    <div className="flex flex-col bg-white shadow-lg rounded-xl border border-gray-50">
      {menus.map((menu, i) => (
        <button
          key={i}
          className={`block w-full px-4 py-2 ${
            type === "shift"
              ? "hover:bg-orange-900 hover:text-white"
              : "hover:bg-primary-500 hover:text-white"
          } disabled:opacity-50`}
          onClick={menu.action}
          disabled={menu.disabled}
        >
          {menu.label}
        </button>
      ))}
    </div>
  );
  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger="click"
      className={"cursor-pointer " + className}
    >
      {image === "settings" ? (
        <img src={settingsIcon} className="w-4 h-4 md:h-5 md:w-5" />
      ) : (
        <i
          className={
            "hover:text-black " + iconSize + " " + iconColor + " " + icon
          }
        />
      )}
    </Dropdown>
  );
};

export default TableActionDropdown;

export const DropActionButtonDropdown = ({ className, menus, type }) => {
  const menu = (
    <div className="flex flex-col bg-white shadow-lg rounded-xl border border-gray-50">
      {menus.map((menu, i) => (
        <button
          key={i}
          className={`block w-full px-4 py-2 ${
            type === "shift"
              ? "hover:bg-orange-900 hover:text-white"
              : "hover:bg-primary-500 hover:text-white"
          } disabled:opacity-50 ${i > 0 ? "border-t border-warmgray-300" : ""}`}
          onClick={menu.action}
          disabled={menu.disabled}
        >
          {menu.label}
        </button>
      ))}
    </div>
  );
  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger="click"
      className={"cursor-pointer " + className}
    >
      <div
        className={`py-2 w-full cursor-pointer rounded-xl px-6 flex items-center justify-center text-white text-sm font-bold shadow-md ${
          type === "shift"
            ? "bg-orange-900 hover:bg-orange-400"
            : "bg-primary-500 hover:bg-primary-400"
        } transition-all hover:opacity-50`}
      >
        <span className="">
          {type === "shift"
            ? "Manage"
            : type === "event"
            ? "Manage"
            : "Manage Position"}
        </span>
        <BsChevronDown className="ml-1.5 text-white text-sm" />
      </div>
    </Dropdown>
  );
};
