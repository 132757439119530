import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DashboardLayout } from "../../components/organisms/Layout";
import QRCodes from "../../api/Qrcode";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { DateFormat } from "../../utils";
import { useSelector } from "react-redux";
import { selectSelectedLocation } from "../../store/authSlice";
import TableLoading from "../../components/Loading/TableLoading";
import Table from "../../components/atoms/Tables/Table";
import { addIcon } from "../../assets/images";

const QRCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const selectedLocation = useSelector(selectSelectedLocation);
  const [datas, setDatas] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL.includes("stagingapi")
    ? "staging.heyhire.app"
    : "heyhire.app";
  const [loading, setLoading] = useState(false);

  const loadQRCodes = async () => {
    setLoading(false);
    try {
      const qrs = await QRCodes.getAll();
      setDatas(qrs.data.data);
      setLoading(true);
    } catch (error) {
      if (error?.response?.status !== 403) {
        toast.error("Api Error!!");
      }
      setLoading(true);
    }
  };

  const downloadQR = (uuid) => {
    fetch(
      `https://api.qrserver.com/v1/create-qr-code/?size=600x600&&color=000000&data=https://${apiUrl}/qr/${uuid}`,
      {
        method: "GET",
        headers: {},
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "qr-code.jpg");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.title = "HeyHire Portal-Manage QR Codes";
    loadQRCodes();
  }, [selectedLocation]);

  return (
    <DashboardLayout darmo={true}>
      <div className="pt-3 md:pt-6 pb-12">
        <div className="overflow-x-auto overflow-hidden">
          {!loading ? (
            <TableLoading Columns={5} />
          ) : (
            <Table
              mainHeader={t("qrcode.manage-qrcode")}
              headers={
                datas?.length > 0
                  ? [
                      "QR Name",
                      "Target",
                      "Date Created",
                      "Click Code to Download",
                      "Edit",
                    ]
                  : ["No QR Code Yet"]
              }
              data={datas?.map((qrcode, index) => (
                <tr key={index}>
                  <td className="pr-3 py-3 text-sm md:text-base text-gray-900 whitespace-nowrap lg:whitespace-normal">
                    {qrcode?.name}
                  </td>
                  <td className="pr-3 py-3 text-sm md:text-base text-gray-900 whitespace-nowrap lg:whitespace-normal">
                    {qrcode?.entity_type}
                    {"->"}
                    <span className="ml-2">
                      {qrcode?.entity_type === "brand"
                        ? qrcode?.brand?.name
                        : qrcode?.entity_type === "location"
                        ? qrcode?.location?.name
                        : qrcode?.name}
                    </span>
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900">
                    {dayjs(qrcode?.created_at).format(DateFormat)}
                  </td>
                  <td className="whitespace-nowrap pr-3 py-3 text-sm md:text-base text-gray-900 flex justify-center">
                    <img
                      width={50}
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=600x600&&color=000000&data=https://${apiUrl}/qr/${qrcode?.uuid}`}
                      alt="alt"
                      className="cursor-pointer"
                      onClick={() => downloadQR(qrcode?.uuid)}
                    />
                  </td>
                  <td className="whitespace-nowrap px-3 py-3 text-sm md:text-base">
                    <div
                      className="text-indigo-600 hover:text-indigo-900 hover:underline cursor-pointer"
                      onClick={() =>
                        navigate(`/business/qr-code/${qrcode.id}/edit`)
                      }
                    >
                      Edit
                    </div>
                  </td>
                </tr>
              ))}
              buttonData={
                <button
                  onClick={() => navigate("/business/qr-code/add")}
                  className="cursor-pointer flex justify-center items-center rounded-full bg-primary-500 px-3.5 py-2 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:bg-primary-400 transition-all hover:opacity-50"
                >
                  <div className="flex">
                    <img
                      src={addIcon}
                      alt="logo"
                      className="mr-2 my-auto w-4 h-4"
                    />
                    Add QR code
                  </div>
                </button>
              }
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default QRCode;
