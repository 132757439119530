import React from "react";
import "react-loading-skeleton/dist/skeleton.css";

const TableLoading = ({ rows = 5, Columns = 3 }) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 border border-warmgray-300 rounded-xl shadow-sm w-full">
      <div className="animate-pulse flex flex-col space-x-4">
        <div className="sm:flex sm:items-center mb-4 mt-6">
          <div className="sm:flex-auto">
            <h1 className="h-2.5 bg-gray-300 rounded w-1/4"></h1>
          </div>
        </div>
        <div className="flow-root mt-4">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="min-w-full divide-y divide-gray-300">
                {/* Header */}
                <div className="flex ">
                  {Array.from({ length: Columns }).map((_, index) => (
                    <div key={index} className="w-full">
                      <div className="h-2 bg-gray-300 rounded w-1/3" />
                    </div>
                  ))}
                </div>

                {/* Rows */}
                <div className="divide-y divide-gray-300 bg-white mt-6">
                  {Array.from({ length: rows }).map((_, rowIndex) => (
                    <div key={rowIndex} className="flex py-4">
                      {Array.from({ length: Columns }).map((_, colIndex) => (
                        <div key={colIndex} className="w-full">
                          <div className="h-2 bg-gray-300 rounded w-1/3" />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableLoading;
